import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import { AppDispatch } from '../../store/store';

const VESTING_OWNERS_UNDO = 'VESTING_OWNERS_UNDO';
const VESTING_OWNERS_REDO = 'VESTING_OWNERS_REDO';
const VESTING_OWNERS_CLEAR = 'VESTING_OWNERS_CLEAR';

interface VestingOwnersEditorState {
    textEditorValue: string;
}

const initialState: VestingOwnersEditorState = {
    textEditorValue: ''
};

const vestingOwnersEditorSlice = createSlice({
    name: 'vestingOwnersEditor',
    initialState,
    reducers: {
        /**
         * Set the current value of the vesting editor to store
         * @param state Slice state
         * @param action Payload with the editor value to set
         */
        setVestingOwnersEditorValue(state, action: PayloadAction<string>) {
            state.textEditorValue = action.payload;
        }
        /**
         * Set the initial value of the vesting editor to store
         * @param state Slice state
         * @param action Payload with the editor value to set
         */
        // setVestingInitialText(state, action: PayloadAction<string>) {
        //     state.initialText = action.payload;
        // }
    }
});
export const { setVestingOwnersEditorValue } = vestingOwnersEditorSlice.actions;
//, setVestingInitialText

const undoableVestingOwnersEditorReducer = undoable(vestingOwnersEditorSlice.reducer, {
    undoType: VESTING_OWNERS_UNDO,
    redoType: VESTING_OWNERS_REDO,
    clearHistoryType: VESTING_OWNERS_CLEAR
});

export const undoEditor = () => (dispatch: AppDispatch) => {
    dispatch({ type: VESTING_OWNERS_UNDO });
};

export const redoEditor = () => (dispatch: AppDispatch) => {
    dispatch({ type: VESTING_OWNERS_REDO });
};

export const clearEditor = () => (dispatch: AppDispatch) => {
    dispatch({ type: VESTING_OWNERS_CLEAR });
};

export default undoableVestingOwnersEditorReducer;
