import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { BusinessSegment } from 'types/dataModels';

interface BusinessSegmentsState {
    businessSegments: BusinessSegment[];
}

const initialState: BusinessSegmentsState = {
    businessSegments: []
};

const businessSegmentsSlice = createSlice({
    name: 'businessSegments',
    initialState,
    reducers: {
        /**
         * Set a list of business segments to store
         * @param state Slice state
         * @param action Payload with Business Segments to set
         */
        setBusinessSegments(state, action: PayloadAction<BusinessSegment[]>) {
            state.businessSegments = action.payload;
        }
    }
});

export const { setBusinessSegments } = businessSegmentsSlice.actions;

/**
 * Fetch business segments and save response data to store
 * @returns {AppThunk}
 */
export const fetchBusinessSegments = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.businessSegment.getBusinessSegments();
        dispatch(setBusinessSegments(response));
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get business segment array: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default businessSegmentsSlice.reducer;
