import { handleError } from 'core/helpers/errorHandler';
import { State } from 'types/dataModels';
import api from '../api';

/**
 * Get all states list
 * @function
 * @category API
 * @returns {State[]}
 */
export const getStates = async (): Promise<State[]> => {
    try {
        const response = await api.getWithCache<State[]>('/api/state');
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
