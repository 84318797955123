import React, { useEffect, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Grid, InputAdornment } from '@mui/material';
import colors from 'core/constants/colors';
import { InputLabelPositions } from 'core/constants/common';
import { Icon, IconPaths } from 'components/styled/Icon';
import TooltipMain from 'components/styled/Tooltip';
import { InputMainProps, StyledInput } from 'components/styled/Input/Input';
import { styled } from '@mui/material/styles';

export interface DebouncedInputFloatProps extends Omit<InputMainProps, 'onChange' | 'value'> {
    value: number;
    onChange?: (inputValue: number) => void;
    onFocus?: (e: React.FocusEvent) => void;
    onBlur?: (inputValue: string) => void;
    inputRef?: React.Ref<HTMLInputElement>;
    dynamic?: boolean;
    config?: boolean;
    onlyPositiveFloatNumber?: boolean;
}

const InputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});

const FLOAT_REGEX = /^[0-9-,.]+$/;
const POSITIVE_FLOAT_REGEX = /^[0-9,.]+$/;

const numberWithCommas = (inputNumber: string) => {
    const clearNumber = numberWithoutCommas(inputNumber);
    return clearNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const numberWithoutCommas = (inputNumber: string) => {
    return inputNumber.replaceAll(',', '');
};

/**
 * Input for float numbers (amount fields)
 * @param {Function} onKeyDown onKeyDown handler
 * @param {string} label Label value
 * @param {InputLabelPositions} labelPosition Label position
 * @param {string} placeHolder Placeholder
 * @param {boolean} error Is have errors
 * @param {boolean} disabled Is input disabled
 * @param {string} helperText Helper text in bottom
 * @param {boolean} withIcon Use with icon
 * @param {Function} onChange OnChange handler
 * @param {string} value Input value
 * @param {number} width Width
 * @param {JSX.Element} icon Icon value
 * @param {string} id Id
 * @param {boolean} withRemoveIcon Render with remove icon
 * @param {Function} removeHandler Remove handler
 * @param {React.Ref<HTMLInputElement>} inputRef Input reference
 * @param {boolean} onlyPositiveFloatNumber true when positive float numbers
 * @constructor
 */
const DebouncedInputFloat = ({
    onKeyDown,
    label,
    labelPosition = InputLabelPositions.top,
    placeHolder = '0.00',
    error,
    disabled,
    helperText,
    withIcon,
    onChange,
    onFocus,
    value,
    width,
    icon,
    id,
    withRemoveIcon = false,
    removeHandler,
    inputRef,
    onBlur,
    dynamic = false,
    config = false,
    onlyPositiveFloatNumber = false
}: DebouncedInputFloatProps) => {
    const [inputValue, setInputValue] = useState<string>(
        value !== null ? (config ? value.toString() : numberWithCommas(value.toFixed(2))) : ''
    );

    useEffect(() => {
        setInputValue(
            value !== null
                ? config
                    ? value.toString()
                    : numberWithCommas(value.toFixed(2))
                : ''
        );
    }, [value]);

    let resultIcon = icon;
    if (error && withIcon) {
        resultIcon = (
            <InputAdornment position="end">
                <WarningIcon color={'error'} />
            </InputAdornment>
        );
    }
    if (withRemoveIcon) {
        resultIcon = (
            <TooltipMain title={'Remove'}>
                <Grid
                    onClick={removeHandler}
                    display={'flex'}
                    sx={{
                        cursor: 'pointer'
                    }}>
                    <InputAdornment position="end">
                        <Icon fill={colors.main.primaryDark} path={IconPaths.close}></Icon>
                    </InputAdornment>
                </Grid>
            </TooltipMain>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: labelPosition === InputLabelPositions.left ? 'row' : 'column',
                gap: '6px',
                width: '100%'
            }}>
            {label && <InputLabel>{label}</InputLabel>}
            <StyledInput
                id={id}
                inputRef={inputRef}
                width={width}
                value={inputValue}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onChange={(e) => {
                    const event = e as React.ChangeEvent<HTMLInputElement>;
                    const value = event.target.value;
                    let regexCheck = FLOAT_REGEX;
                    if (onlyPositiveFloatNumber) regexCheck = POSITIVE_FLOAT_REGEX;
                    if (value !== '' && !regexCheck.test(value)) {
                        return;
                    }
                    setInputValue(value);
                    if (onChange && dynamic) {
                        const num = parseFloat(numberWithoutCommas(value));
                        onChange(num);
                    }
                }}
                onBlur={() => {
                    if (onBlur && dynamic) onBlur(inputValue);
                    const parsedValue = isNaN(parseFloat(numberWithoutCommas(inputValue)))
                        ? ''
                        : parseFloat(numberWithoutCommas(inputValue)).toFixed(2);
                    const inputValueNumber = inputValue === '' ? null : Number(inputValue);
                    if (value !== inputValueNumber || config) {
                        onChange(
                            parsedValue === ''
                                ? null
                                : config
                                ? parseInt(parsedValue)
                                : parseFloat(parsedValue)
                        );
                    }
                    setInputValue(
                        config ? parsedValue.split('.')[0] : numberWithCommas(parsedValue)
                    );
                }}
                onClick={(event) => event.stopPropagation()}
                placeholder={placeHolder}
                error={error}
                disabled={disabled}
                helperText={error && helperText}
                InputProps={{
                    endAdornment: resultIcon,
                    'aria-label': 'debouncedInputFloat'
                }}
            />
        </Box>
    );
};

export default DebouncedInputFloat;
