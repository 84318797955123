import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Divider,
    ListItemText,
    SelectChangeEvent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getPlural } from 'core/helpers/getPlural';
import { ISelectProps } from 'components/styled/Select/Select';
import { InputLabelPositions } from 'core/constants/common';
import colors from 'core/constants/colors';
import CheckboxMain from 'components/styled/Checkbox';

const DefaultSelect = styled(Select)({
    '&.MuiInput-root': {
        width: '100%',
        backgroundColor: colors.main.primaryLight,
        border: `1px solid ${colors.main.secondaryLight}`,
        padding: '8px',
        height: '32px',
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        minWidth: '50px',
        color: colors.main.primaryDark,
        '& .MuiSelect-select': {
            padding: 0
        }
    }
});

const ITEM_HEIGHT = 32;

const InputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});

const StyledMenuItem = styled(MenuItem)({
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    padding: '10px 34px 10px 12px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px'
});

const ItemName = styled(ListItemText)({
    paddingTop: '2px',
    '& .MuiTypography-root': {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '14px'
    }
});

export interface MultiSelectProps
    extends Omit<ISelectProps, 'selectOnChange' | 'selectValue' | 'label'> {
    selectOnChange: (event: SelectChangeEvent<string[]>) => void;
    selectValue: string[];
    label: string;
}

/**
 * Multi select component (extended select component)
 * @param {Function} selectOnChange onChange handler
 * @param {string[]} selectValue Current select value
 * @param {SelectItem[]} menuItems Dropdown items
 * @param {InputLabelPositions} labelPosition Label position
 * @param {string} label Label value
 * @param {number} minWidth min width
 * @param {boolean} disabled Is disabled
 * @constructor
 */
const MultiSelect = ({
    selectOnChange,
    selectValue,
    menuItems,
    labelPosition,
    label,
    minWidth = 50,
    disabled = false
}: MultiSelectProps) => {
    const [dropDownWidth, setDropDownWidth] = useState(0);
    const ref = useRef(null);
    useEffect(() => {
        setDropDownWidth(ref.current.node.offsetWidth);
    }, [ref.current]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: labelPosition === InputLabelPositions.left ? 'row' : 'column',
                gap: '6px'
            }}>
            {label && (
                <InputLabel
                    sx={{
                        width: 'max-content'
                    }}>
                    {label}
                </InputLabel>
            )}
            <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: minWidth, margin: 0, width: '100%' }}>
                <DefaultSelect
                    multiple
                    inputRef={ref}
                    disabled={disabled}
                    value={selectValue}
                    onChange={selectOnChange}
                    disableUnderline
                    displayEmpty
                    renderValue={(selected: string[]) =>
                        selected.length
                            ? menuItems.length === selected.length
                                ? `All ${getPlural(label)}`
                                : `${selected.length} Selected`
                            : 'None'
                    }
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: ITEM_HEIGHT * 10,
                                minWidth: dropDownWidth,
                                borderRadius: 0,
                                border: `1px solid ${colors.main.secondaryLight}`,
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)'
                            }
                        }
                    }}>
                    <StyledMenuItem key={0} value={'All'}>
                        <CheckboxMain
                            value={''}
                            checked={!!selectValue.length}
                            indeterminate={
                                selectValue.length > 0 && selectValue.length < menuItems.length
                            }
                            onChange={() => null}
                        />
                        <ItemName primary={`All ${getPlural(label)}`} />
                    </StyledMenuItem>
                    <Divider />
                    {menuItems.map((element) => {
                        return (
                            <StyledMenuItem key={element.id} value={element.value}>
                                <CheckboxMain
                                    value={element.value}
                                    checked={selectValue.includes(element.value)}
                                    onChange={() => null}
                                />
                                <ItemName primary={element.value} />
                            </StyledMenuItem>
                        );
                    })}
                </DefaultSelect>
            </FormControl>
        </Box>
    );
};

export default MultiSelect;
