import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PayloadTypes {
    id: string;
}

const initialState = {
    buyerForms: [] as string[]
};

const testOrderBuyerFormsSlice = createSlice({
    name: 'testOrderBuyerForms',
    initialState,
    reducers: {
        /**
         * Add a new buyer form to test order
         * @param state Slice state
         * @param action Payload with the ID to add to buyer forms
         */
        addBuyerForm(state, action: PayloadAction<PayloadTypes['id']>) {
            state.buyerForms.push(action.payload);
        },
        /**
         * Remove a buyer form from test order state
         * @param state Slice state
         * @param action Payload with the ID to remove from buyer forms
         */
        removeBuyerForm(state, action: PayloadAction<PayloadTypes['id']>) {
            const elementIndex = state.buyerForms.indexOf(action.payload);
            if (elementIndex >= 0) {
                state.buyerForms.splice(elementIndex, 1);
            }
        },
        /**
         * Remove all buyer forms from test order
         * @param state Slice state
         */
        removeAllBuyerForms(state) {
            state.buyerForms = initialState.buyerForms;
        },
        /**
         * Set the buyer forms in test order
         * @param state Slice state
         * @param action Payload with array of IDs to set
         */
        setBuyerForms(state, action: PayloadAction<typeof initialState.buyerForms>) {
            state.buyerForms = action.payload;
        }
    }
});

export const { addBuyerForm, removeBuyerForm, removeAllBuyerForms, setBuyerForms } =
    testOrderBuyerFormsSlice.actions;

export default testOrderBuyerFormsSlice.reducer;
