import { makeStyles } from "tss-react/mui";
import colors from '../../../core/constants/colors';

const styles = makeStyles()(theme => ({
    darkIconBtnHover: {
        '&:hover': {
            backgroundColor: `${colors.main.tertiaryDark} !important`,
            textDecoration: 'none',
            boxShadow: `0px 2px 4px 1px rgba(0, 0, 0, 0.2),
                0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12)`
        }
    },
    lightIconBtnHover: {
        '&:hover': {
            backgroundColor: `${colors.main.tertiaryLight} !important`,
            textDecoration: 'none',
            boxShadow: `0px 2px 4px 1px rgba(0, 0, 0, 0.2),
                0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12)`
        }
    }
}));

export default styles;
