import { Profile } from 'types/dataModels';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';

interface ProfileState {
    profile: Profile;
}

const initialState: ProfileState = {
    profile: {} as Profile
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfileData(state, action: PayloadAction<ProfileState['profile']>) {
            state.profile = action.payload;
        }
    }
});

export const { setProfileData } = profileSlice.actions;

/**
 * Fetch currently logged in user profile data from BE
 * @returns {AppThunk}
 */
export const fetchProfileData = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.profile.getProfileDataApi();
        dispatch(setProfileData(response));
    } catch (err) {
        dispatch(setProfileData({} as Profile));
    }
};

export default profileSlice.reducer;
