import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IVestingOwnerFullScreenSlice {
    /**
     * Full screen view of the vesting editor
     */
    isVestingFullScreen: boolean;
    vestingEditorInitialValue: string;
}

const initialState: IVestingOwnerFullScreenSlice = {
    isVestingFullScreen: false,
    vestingEditorInitialValue: ''
};

const vestingOwnerEditorExtrasSlice = createSlice({
    name: 'vestingOwnerEditorFullScreen',
    initialState,
    reducers: {
        /**
         * Set full screen view of the vesting editor
         * @param state Slice state
         * @param action payload with the isFullScreen value to set
         */
        setVestingIsFullScreen(
            state: IVestingOwnerFullScreenSlice,
            action: PayloadAction<IVestingOwnerFullScreenSlice['isVestingFullScreen']>
        ) {
            state.isVestingFullScreen = action.payload;
        },
        /**
         * Set the value of the full screen vesting editor value
         * @param state Slice state
         * @param action Payload with the editor value to set
         */
        setVestingInitialValue(
            state: IVestingOwnerFullScreenSlice,
            action: PayloadAction<IVestingOwnerFullScreenSlice['vestingEditorInitialValue']>
        ) {
            state.vestingEditorInitialValue = action.payload;
        }
    }
});

export const { setVestingIsFullScreen, setVestingInitialValue } =
    vestingOwnerEditorExtrasSlice.actions;
export default vestingOwnerEditorExtrasSlice.reducer;
