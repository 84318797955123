import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FileUploadError = {
    error: boolean;
    message: string;
};

interface FileDropzoneState {
    files: File[];
    fileUploadError: FileUploadError;
}

export const initialState: FileDropzoneState = {
    files: [],
    fileUploadError: {
        error: false,
        message: ''
    }
};

const fileDropzoneSlice = createSlice({
    name: 'fileDropzone',
    initialState,
    reducers: {
        /**
         * Set the files
         * @param state Slice state
         * @param action Payload with the files
         */
        setFiles(state, action: PayloadAction<File[]>) {
            state.files = action.payload;
        },
        /**
         * Set file upload error
         * @param state Slice state
         * @param action Payload with form data for new key document
         */
        setFileUploadError(state, action: PayloadAction<FileUploadError>) {
            state.fileUploadError = action.payload;
        }
    }
});

export const { setFiles, setFileUploadError } = fileDropzoneSlice.actions;
export default fileDropzoneSlice.reducer;
