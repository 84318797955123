import { GridColDef } from '@mui/x-data-grid';
import {
    CategorySchema,
    ConfigDataRow,
    cdKey,
    cdResult,
    RowEntry,
    ForeignKey,
    ColumnType,
    UpdateFilterObj,
    CategoryColumnDefinition,
    ForeignKeyWithMenuItems
} from 'types/configManager';
import { numberWithoutCommas } from 'core/helpers/convertToFromCommas';
import queryClient from 'core/services/QueryClient';
import {
    DeleteComponent,
    createCellComponent,
    createDisplayComponent
} from 'core/helpers/determineInputRowComponent';
import { createHeaderComponent } from './determineInputHeaderComponent';
import api from '../../core/api';

type GridAlignment = 'left' | 'center' | 'right';

interface ColHeaderOptions {
    columnName?: string;
    deleteHeader?: boolean;
    type?: number;
    width?: number;
    filterable?: boolean;
    sortable?: boolean;
    disableColumnMenu?: boolean;
    headerAlign?: GridAlignment;
    flex?: number;
    columnType?: ColumnType; // 0 = default, 1 = fk, 2 = fd
    filterId?: string;
    fkWithMenuItems?: ForeignKeyWithMenuItems;
}

export interface ColCallbackOptions {
    updateFiltersCallback?: (filter: UpdateFilterObj) => void;
    deleteRowCallback?: (configId: string) => void;
}

/**
 * Columns follow a specific pattern to be displayed. Three of the columns are hard coded;
 * Category | Name | [FieldDefinitions] | [ForeignKeys] | Delete
 * @param headerDefs
 * @param updateFiltersCallback
 * @param deleteRowCallback
 * @returns {GridColDef[]}
 */
const generateColumnStructure = async (
    headerDefs: CategorySchema,
    updateFiltersCallback: (filter: UpdateFilterObj) => void,
    deleteRowCallback: (configId: string) => void
) => {
    const fkDropDownLists = await getDropdownListsResults(headerDefs.foreignKeyEntities);
    const gridHeaders: GridColDef[] = [];

    const funcs: ColCallbackOptions = {
        updateFiltersCallback,
        deleteRowCallback
    };
    gridHeaders.push(
        createColHeaderEntry({ columnName: 'Category', columnType: ColumnType.Default }, funcs)
    );

    gridHeaders.push(
        createColHeaderEntry({ columnName: 'Name', columnType: ColumnType.Name }, funcs)
    );

    const fds: GridColDef[] = [];
    headerDefs.fieldDefinitions.map((fd) => {
        fds.push(
            createColHeaderEntry(
                {
                    columnName: fd.fieldName,
                    type: fd.fieldType,
                    columnType: ColumnType.FieldDefinition,
                    filterId: fd.id
                },
                funcs
            )
        );
    });

    const fks: GridColDef[] = [];
    headerDefs.foreignKeyEntities.map((fk) => {
        const ddArr = fkDropDownLists.find((key) => key?.id === fk?.id);
        fks.push(
            createColHeaderEntry(
                {
                    columnName: fk.name,
                    columnType: ColumnType.ForeignKey,
                    filterId: fk.id,
                    fkWithMenuItems: ddArr
                },
                funcs
            )
        );
    });

    const gridHeadersWithFilters = [
        ...gridHeaders,
        ...fds,
        ...fks,
        createColHeaderEntry(
            {
                columnName: 'Delete',
                deleteHeader: true,
                columnType: ColumnType.Default
            },
            funcs
        )
    ];

    const gridHeadersWithoutFilters = gridHeadersWithFilters.map((item) => {
        const newItem = { ...item };
        delete newItem.renderHeader;
        return newItem;
    });

    /** Populate the object we use to  */
    const headerStructure: CategoryColumnDefinition = {
        id: headerDefs.id,
        filterHeaders: {
            withComponents: gridHeadersWithFilters,
            withoutComponents: gridHeadersWithoutFilters
        },
        componentData: {
            configCategory: headerDefs.configCategory,
            fieldDefs: headerDefs.fieldDefinitions,
            foreignKeys: fkDropDownLists
        }
    };

    // console.log(headerStructure)

    return headerStructure;
};

/**
 * Generated based off the column definitions, should contain enough information for a proper row
 * This is using the response data to populate rows, this takes care of the different input
 * components for the rows, and also integrates with the add row modal form to create inputs for that.
 * TODO: integrate with add row modal form
 * @param rows The data we get back from the server
 * @param configCategory
 * @returns {RowEntry[]} The structure of a row changes with every change in category, and there are no mandatory types.
 * If this were to become an issue then we would need to predifine our own set of RowTypes, I do not feel that is necessary
 * at the moment. This would not be easily extensible either, unless we generalize most rows, and hope the extras are populated
 * accordingly
 */
const generateRowsForDataGrid = (rows: ConfigDataRow[], configCategory: string) => {
    const dataGridRows: RowEntry[] = [];
    rows.map((row) => {
        dataGridRows.push(
            createRowEntry(row.id, configCategory, row.name, row.keys, row.results)
        );
    });

    return dataGridRows;
};

export { generateColumnStructure, generateRowsForDataGrid };

/**
 * @param object These are fields relating to the GridColDef object from MUI,
 * type referes to the enum value we recieve from the BE
 * @param funcs The callback for how we handle changing filter values
 * @returns {GridBaseColDef} GridColDef for the column header.
 */
const createColHeaderEntry = (
    {
        columnName = '!ERR!',
        deleteHeader = false,
        type = 0,
        width = 200,
        filterable = true,
        sortable = true,
        disableColumnMenu = false,
        headerAlign = 'left' as GridAlignment,
        flex = 1,
        columnType = ColumnType.Default, // 0 = default, 1 = fk, 2 = fd
        filterId = '',
        fkWithMenuItems
    }: ColHeaderOptions,
    funcs: ColCallbackOptions
) => {
    if (deleteHeader) {
        const delHeader: GridColDef = {
            field: columnName,
            headerName: columnName,
            renderCell: (params) => DeleteComponent(funcs, params.id.toString())
        };
        return delHeader;
    }

    if (columnType === ColumnType.Default) {
        const tempHeader: GridColDef = {
            field: columnName,
            headerName: columnName,
            width: width,
            filterable: filterable,
            align: 'center',
            sortable: sortable,
            disableColumnMenu: disableColumnMenu,
            headerAlign: headerAlign,
            editable: false,
            renderHeader: (params) =>
                createHeaderComponent(
                    columnType,
                    filterId,
                    params,
                    funcs.updateFiltersCallback,
                    type
                )
        };
        return tempHeader;
    }

    const editable = columnType === ColumnType.FieldDefinition;
    const tempHeader: GridColDef = {
        field: columnName,
        headerName: columnName,
        width,
        filterable,
        sortable,
        disableColumnMenu,
        headerAlign,
        align: 'center',
        editable,
        flex,
        valueFormatter: (params) => {
            if (params.value === null) {
                return 'None';
            }
            if (typeof params.value === 'number') {
                return numberWithoutCommas(params.value.toString());
            } else {
                return params.value;
            }
        },
        renderHeader: (params) =>
            createHeaderComponent(
                columnType,
                filterId,
                params,
                funcs.updateFiltersCallback,
                type,
                fkWithMenuItems
            ),
        renderEditCell: (params) =>
            createCellComponent(
                columnType,
                params,
                type
                // fkWithMenuItems,
                // params.colDef.computedWidth
            ),
        renderCell: (params) => createDisplayComponent(params, type)
    };
    return tempHeader;
};

/**
 * This creates rows. This is looks janky because we are not given any sort
 * of structure pre-category fetch, so this creates those row structures once
 * we have the data
 * @param id
 * @param configCategory
 * @param name
 * @param keys
 * @param results
 * @returns {{}}
 */
export const createRowEntry = (
    id = '',
    configCategory = '!ERR! CAT',
    name = '!ERR! NAME',
    keys: cdKey,
    results: cdResult[] = []
) => {
    let tempRow = {
        id: `${id}`,
        Category: `${configCategory}`,
        Name: `${name}`,
        results: results
    };

    const keyOpts: { [key: string]: string } = {};
    for (const key in keys) {
        keyOpts[key] = keys[key];
    }

    tempRow = { ...tempRow, ...keyOpts };

    const resultOpts: { [key: string]: string | number } = {};
    results.forEach((result) => {
        resultOpts[result.name] = result.value;
    });
    tempRow = { ...tempRow, ...resultOpts };
    return tempRow;
};

/**
 * Function to get all dropdown list items asynchronously
 * @param fks foreign keys to get lists for
 * @returns {ForeignKeyWithMenuItems[]} object holding relevant foreign key information
 */
const getDropdownListsResults = async (fks: ForeignKey[]) => {
    const promises = fks.map(async (fk) => {
        try {
            const response = await queryClient.fetchQuery({
                queryKey: [fk.optionUri],
                queryFn: () => api.configManager.getDropdownResults(fk.optionUri)
            });
            const temp: ForeignKeyWithMenuItems = {
                id: fk.id,
                name: fk.name,
                optionUri: fk.optionUri,
                dropdownMenuItems: response
            };
            return temp;
        } catch (err) {
            const temp: ForeignKeyWithMenuItems = {
                id: fk.id,
                name: fk.name,
                optionUri: fk.optionUri,
                dropdownMenuItems: { items: [] }
            };
            return temp;
        }
    });

    const responses = await Promise.all(promises);

    return responses;
};
