import store from 'core/store/store';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './styles/fonts.scss';
import './styles/index.scss';
import ReactGA from 'react-ga';
import { MSAL_CONFIG, GTMIDS } from 'core/constants/common';

const msalConfig = JSON.parse(localStorage.getItem(MSAL_CONFIG));
const gtmId: string = GTMIDS?.[msalConfig?.env || 'Development'];

ReactGA.initialize(gtmId);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
