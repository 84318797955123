import { handleError } from 'core/helpers/errorHandler';
import { DocumentCategory } from 'types/dataModels';
import api from '../api';

/**
 * This function gets a list of document categories from the server and returns them to the caller.
 * @function
 * @category API
 * @subcategory documentCategory
 * @param {string} id - document type id
 * @returns {DocumentCategory[]} DocumentCategory[]
 */
export const getDocumentCategory = async (id: string): Promise<DocumentCategory[]> => {
    try {
        const response = await api.getWithCache<DocumentCategory[]>(
            `/api/ExaminerDocumentType/${id}/documentCategory`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
