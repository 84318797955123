import React, { ChangeEventHandler } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import colors from 'core/constants/colors';
import { InputMainProps } from 'components/styled/Input/Input';
import { styled } from '@mui/material/styles';

export const StyledInput = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'width'
})<Partial<Omit<InputMainProps, 'onChange'>>>(({ width }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: colors.main.primaryLight,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: '0',
        padding: '0px',
        width: width,
        caretColor: colors.main.accentHighlight,
        '& fieldset': {
            borderColor: colors.main.secondaryLight
        },
        '&.Mui-focused fieldset': {
            borderColor: colors.main.accentHighlight
        },
        '&.Mui-error fieldset': {
            borderColor: colors.additional.error_fail
        }
    },
    '& .MuiOutlinedInput-input': {
        padding: '10px'
    },
    '& .MuiFormHelperText-root': {
        marginLeft: 0,
        marginTop: '2px'
    },
    '& .MuiInputAdornment-positionStart': {
        marginTop: '8px',
        height: '100%',
        marginBottom: 'auto'
    }
}));

export const InputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});

/**
 * Text area styled component
 * @param {Function} onKeyDown onKeyDown handler
 * @param {string} label Label value
 * @param {string} placeHolder Placeholder
 * @param {boolean} error Is have errors
 * @param {boolean} disabled Is input disabled
 * @param {string} helperText Helper text in bottom
 * @param {Function} onChange OnChange handler
 * @param {string} value Input value
 * @param {number} width Width
 * @param {number} rows Number of rows
 * @param {boolean} fullWidth Is in full width
 * @param {React.Ref<HTMLInputElement>} inputRef Input reference
 * @param {Function} onMouseUp Input only for numbers
 * @component
 * @returns {JSX.Element}
 */
const TextArea = ({
    onKeyDown,
    label,
    placeHolder,
    error,
    disabled,
    helperText,
    onChange,
    value,
    width,
    rows,
    fullWidth = true,
    onMouseUp,
    inputRef
}: Omit<InputMainProps, 'onChange'> & {
    rows: number;
    fullWidth?: boolean;
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '6px'
            }}>
            <InputLabel>{label}</InputLabel>
            <StyledInput
                inputRef={inputRef}
                onMouseUp={onMouseUp}
                multiline={true}
                rows={rows}
                fullWidth={fullWidth}
                width={width}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeHolder}
                error={error}
                disabled={disabled}
                helperText={error && helperText}
                InputProps={{
                    endAdornment: error && (
                        <InputAdornment position="start">
                            <WarningIcon color={'error'} />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default TextArea;
