import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { DocumentNote } from 'types/dataModels';

/**
 * Add document note.
 * @function
 * @category API
 * @subcategory examOrderDocumentNotes
 * @param {string} orderId The id of the order
 * @param {string} documentId The id of the document
 * @param {string} documentNote The new note text
 * @returns {DocumentNote}
 */
export const addExamOrderDocumentNote = async (
    orderId: string,
    documentId: string,
    documentNote: string
): Promise<DocumentNote> => {
    try {
        const response = await api.post<{ note: string }, DocumentNote>(
            `/api/examOrder/${orderId}/document/${documentId}/note`,
            { note: documentNote }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update existing note text
 * @function
 * @category API
 * @subcategory examOrderDocumentNotes
 * @param {string} orderId The id of the order
 * @param {string} documentId The id of the document
 * @param {string} documentNote The new note text
 * @returns {DocumentNote}
 */
export const updateExamOrderDocumentNote = async (
    orderId: string,
    documentId: string,
    documentNote: DocumentNote
): Promise<DocumentNote> => {
    try {
        const response = await api.put<DocumentNote, DocumentNote>(
            `/api/examOrder/${orderId}/document/${documentId}/note`,
            documentNote
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Delete document note.
 * @function
 * @category API
 * @subcategory examOrderDocumentNotes
 * @param {string} orderId The id of the order
 * @param {string} documentId The id of the document
 * @param {string} noteId The id of the note in document
 * @returns {void}
 */
export const deleteExamOrderDocumentNoteById = async (
    orderId: string,
    documentId: string,
    noteId: string
): Promise<void> => {
    try {
        const response = await api.delete(
            `/api/examOrder/${orderId}/document/${documentId}/note/${noteId}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
