import api from 'core/api';
import { useMutation } from '@tanstack/react-query';

const deleteDynamicConfig = async (modelId: string, configId: string) => {
    const res = await api.configManager.deleteDynamicConfig(modelId, configId);
    return res;
};

const useDeleteDynamicConfigMutation = (modelId: string) => {
    const deleteConfigMutation = useMutation({
        mutationFn: async (data: { configId: string }) =>
            deleteDynamicConfig(modelId, data.configId)
    });

    return { deleteConfigMutation };
};

export default useDeleteDynamicConfigMutation;
