import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IkeyDocumentsGroupProps {
    /**
     * show only tagged documents
     */
    isTaggedOnly: boolean;
    /**
     * show only documents with selected category
     */
    currentCategory: string;
}

export interface ISetKeyDocumentGroupPayloadAction {
    /**
     * payload data with filters
     */
    data: IkeyDocumentsGroupProps;
    /**
     * panel group ID
     */
    keyGroupId: string;
}

export interface IKeyDocumentsGroupingSlice {
    [key: string]: IkeyDocumentsGroupProps;
}

const initialState: IKeyDocumentsGroupingSlice = {};

const keyDocumentsGroupingSlice = createSlice({
    name: 'keyDocumentsGrouping',
    initialState,
    reducers: {
        /**
         * Set UI controls data for a particular key document group
         * @param state Slice state
         * @param action Payload with the key document group data to set
         */
        setKeyDocumentsGroup(
            state: IKeyDocumentsGroupingSlice,
            action: PayloadAction<ISetKeyDocumentGroupPayloadAction>
        ) {
            state[action.payload.keyGroupId] = action.payload.data;
        }
    }
});

export const { setKeyDocumentsGroup } = keyDocumentsGroupingSlice.actions;
export default keyDocumentsGroupingSlice.reducer;
