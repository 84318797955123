import { handleError } from 'core/helpers/errorHandler';
import { OrderLegalEntity } from 'types/examOrderDataModel';
import api from '../api';

/**
 * Add new party to order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {Omit<OrderLegalEntity, 'id' | 'isBusiness'>} partyData - new party data
 * @returns {OrderLegalEntity}
 */
export const addOrderPartyApi = async (
    orderId: string,
    partyData: Omit<OrderLegalEntity, 'id' | 'isBusiness'>
): Promise<OrderLegalEntity> => {
    try {
        const response = await api.post<
            Omit<OrderLegalEntity, 'id' | 'isBusiness'>,
            OrderLegalEntity
        >(`/api/examOrder/${orderId}/parties`, partyData);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update order party
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} partyId - Target party id
 * @param {Omit<OrderLegalEntity, 'id' | 'isBusiness'>} partyData - New party data
 * @returns {OrderLegalEntity}
 */
export const updateOrderPartyApi = async (
    orderId: string,
    partyId: string,
    partyData: Omit<OrderLegalEntity, 'id'>
): Promise<OrderLegalEntity> => {
    const response = await api.put<Omit<OrderLegalEntity, 'id'>, OrderLegalEntity>(
        `/api/examOrder/${orderId}/parties/${partyId}`,
        partyData
    );
    return response.data;
};

/**
 * Remove order party
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} partyId - Target party id
 * @returns {void}
 */
export const removeOrderPartyApi = async (orderId: string, partyId: string): Promise<void> => {
    try {
        const response = await api.delete(`/api/examOrder/${orderId}/parties/${partyId}`);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
