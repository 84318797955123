import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import colors from 'core/constants/colors';
import React from 'react';

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        borderRadius: '4px',
        width: '18px',
        minWidth: '18px',
        height: '19px',
        backgroundColor: colors.main.accentHighlight,
        color: colors.main.primaryLight,
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px'
    }
});

interface BadgeMain {
    badgeContent: React.ReactNode;
    children: JSX.Element;
}

/**
 * Badge component
 * @param {React.ReactNode} badgeContent Content for rendering as badge
 * @param {JSX.Element} children Child component
 * @constructor
 */
const BadgeMain = ({ badgeContent, children }: BadgeMain) => {
    return (
        <StyledBadge
            badgeContent={badgeContent}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}>
            {children}
        </StyledBadge>
    );
};

export default BadgeMain;
