import { LegalDescriptionValue } from 'types/dataModels';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk, RootState } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { setWorkbenchExamOrderReviewState } from 'core/features/workbenchTabs/workbenchTabsSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { ValueDocLink } from 'types/dataModels';

interface ExamOrderLegalDescriptionState {
    examOrderLegalDescription: LegalDescriptionValue[];
}

const initialState: ExamOrderLegalDescriptionState = {
    examOrderLegalDescription: []
};

const examOrderLegalDescriptionSlice = createSlice({
    name: 'examOrderLegalDescription',
    initialState,
    reducers: {
        /**
         * Set legal description data from BE to state
         * @param state Slice state
         * @param action Payload with the legal´description list to set
         */
        setExamOrderLegalDescriptionData(
            state: ExamOrderLegalDescriptionState,
            action: PayloadAction<LegalDescriptionValue[]>
        ) {
            state.examOrderLegalDescription = action.payload;
        },
        /**
         * Set hasPulseFiles flag of document Legal data on document image upload OR delete
         * @param state Slice state
         * @param action Payload with document Id and hasPulseFile flag
         */
        setHasPulseFilesFlagOfLegalData(
            state: ExamOrderLegalDescriptionState,
            action: PayloadAction<{
                docId: string;
                hasPulseFiles: boolean;
            }>
        ) {
            const legalDataToUpdate = state.examOrderLegalDescription.find(
                (legalData) => legalData?.documentId === action.payload.docId
            );
            if (legalDataToUpdate)
                legalDataToUpdate.hasPulseFile = action.payload.hasPulseFiles;
        }
    }
});

export const { setExamOrderLegalDescriptionData, setHasPulseFilesFlagOfLegalData } =
    examOrderLegalDescriptionSlice.actions;

/**
 * Fetch exam order legal description data from BE
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchExamOrderLegalDescriptionData =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderLegalDescription.getExamOrderLegalDescription(
                orderId
            );
            dispatch(setExamOrderLegalDescriptionData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get exam order legal description: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Apply exam order legal description data
 * @param {string} orderId ID of the order
 * @param {string} legalDescriptionId ID of the legal description to apply to the order
 * @param {string} instrumentString - Instrument string value,
 * @param {ValueDocLink[]} linkArray - attached document array,
 * @returns {AppThunk}
 */
export const applyExamOrderLegalDescriptionThunk =
    (
        orderId: string,
        legalDescriptionId: string,
        instrumentString: string,
        linkArray?: ValueDocLink[]
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderLegalDescription.applyInstrumentAsOrderLegal(
                orderId,
                legalDescriptionId,
                instrumentString,
                linkArray
            );
            dispatch(setExamOrderLegalDescriptionData(response.legalDescriptions));
            dispatch(setWorkbenchExamOrderReviewState(response.reviewState));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Apply exam order legal description: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Save final exam order legal description data
 * @param {string} orderId ID of the order
 * @param {string} value Value to save as order legal description
 * @param {ValueDocLink[]} docLinks List of document links to save
 * @returns {AppThunk}
 */
export const applyFinalExamOrderLegalDescriptionThunk =
    (orderId: string, value: string, docLinks: ValueDocLink[]): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderLegalDescription.saveFinalOrderLegal(
                orderId,
                value,
                docLinks
            );
            dispatch(setExamOrderLegalDescriptionData(response.legalDescriptions));
            dispatch(setWorkbenchExamOrderReviewState(response.reviewState));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Save final exam order legal description: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

// export const addDocumentValueLink

export const selectTaggedLegalInstruments = createSelector(
    (state: RootState) => state.examOrderKeyDocumentGroupData.examOrderDocumentGroup,
    (state: RootState) => state.examOrderLegalDescriptionData.examOrderLegalDescription,
    (documentGroups, legalInstruments) => {
        const taggedDocIds = documentGroups
            .flatMap((docGroup) => docGroup.documents)
            .filter((doc) => doc.isTagged)
            .map((doc) => doc.id);
        return legalInstruments.filter((instrument) =>
            taggedDocIds.includes(instrument.documentId)
        );
    }
);

export default examOrderLegalDescriptionSlice.reducer;
