import { GridColDef, GridRowsProp } from '@mui/x-data-grid';

/**
 *
 */
export interface Category {
    name: string;
    id: string;
}

export interface ICategoryModelsData {
    items: Category[];
}

export interface CategorySchemaWHeaders extends CategorySchema {
    dgHead: GridColDef[];
}
export interface CategorySchema {
    id: string;
    configCategory: string;
    foreignKeyEntities: ForeignKey[];
    fieldDefinitions: FieldDefinition[];
}

/**
 * This is the object that holds the relevant data to pass around
 */
// export interface ColumnDefinitionDto {
//     columnDefinions: CategorySchema;

// }

export interface HeaderItem {
    id: string;
    name: string;
    type: number;
}

export interface cdResult {
    keyId: string;
    name: string;
    value: string | number;
}

export interface cdKey {
    [key: string]: string;
}

export interface RowColumnResponses {
    colDefs: CategorySchema;
    rowDef: ConfigDataRow[];
}

export interface ConfigDataRow {
    id: string;
    modelId: string;
    name: string;
    keys: cdKey;
    results: cdResult[];
}

/**
 * This is the data that we will pass around for the data grid
 */
export interface ConfigManagerDataGrid {
    columns: GridColDef[];
    rows: GridRowsProp[];
    meta: CMDGMetaData;
}

export interface CMDGMetaData {
    id: string;
}

export interface Filter {
    id: string;
    name: string;
    value: string;
}

export interface FilterThing {
    [id: string]: Filter[];
}

export interface Sort {
    name: string;
    direction: 0 | 1;
}

export interface PagedDataResponse {
    count: number;
    data: RowEntry[];
    page: number;
    pageLimit: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
    configCategory: string;
}

export interface RowEntry {
    id: string;
    Category: string;
    Name: string;
    [key: string]: string | number | cdResult[];
    results: cdResult[];
}

export interface NewRowEntry {
    modelId: string;
    name: string;
    keys: { [k: string]: string };
    results: { keyId: string; name: string; value: string }[];
}

export interface UpdateFilterObj {
    filters: Filter[];
    colType: number;
}

export enum ColumnType {
    Default,
    Name,
    ForeignKey,
    FieldDefinition
}

export interface ColumnFilters {
    ForeignKeyFilters: Filter[];
    FieldDefinitionFilters: Filter[];
    Name: Filter;
    Sorting: Sort;
}

/**
 * The special object that defines the cache structure
 * for the useQuery hook for category definitions
 */
export interface CategoryColumnDefinition {
    id: string;
    filterHeaders: ComponentHeaders;
    componentData: ComponentData;
}

interface ComponentHeaders {
    withComponents: GridColDef[];
    withoutComponents: GridColDef[];
}

export interface ComponentData {
    configCategory: string;
    fieldDefs: FieldDefinition[];
    foreignKeys: ForeignKeyWithMenuItems[];
}

export interface ForeignKeyWithMenuItems extends ForeignKey {
    dropdownMenuItems: DropdownItems;
}

export interface ForeignKey {
    id: string;
    name: string;
    optionUri: string;
}

export interface FieldDefinition {
    id: string;
    dropdownUri: string;
    dropdownItems: DropdownItem[];
    fieldName: string;
    fieldType: number;
}

export interface DropdownItem {
    id: string;
    name: string;
}

export interface DropdownItems {
    items: DropdownItem[];
}

export interface DynamicConfigFilterDto {
    modelId: string;
    configName: string;
    resultFilters: Filter[];
    foreignKeyFilters: Filter[];
    fieldSorting: Sort;
    page: number;
    pageSize: number;
}
