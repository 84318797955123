import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersListTableHeaders } from 'core/constants/common';

const initialState = {
    currentSortingColumn: OrdersListTableHeaders.sla
};

const sortingSlice = createSlice({
    name: 'sorting',
    initialState,
    reducers: {
        /**
         * Set the column to sort orders by
         * @param state Slice state
         * @param action Payload with the enum value of the column to sort orders by
         */
        setCurrentSortingColumn(state, action: PayloadAction<OrdersListTableHeaders>) {
            state.currentSortingColumn = action.payload;
        }
    }
});

export const { setCurrentSortingColumn } = sortingSlice.actions;
export default sortingSlice.reducer;
