import * as profile from './profile';
import * as state from './state';
import * as county from './county';
import * as orders from './orders';
import * as stateCounty from './stateCounty';
import * as client from './client';
import * as productTypes from './productType';
import * as projectStatuses from './projectStatus';
import * as documentTypes from './documentType';
import * as documentCategories from './documentCategory';
import * as interestEstateTypes from './interestEstateType';
import * as businessSegment from './businessSegment';
import * as zipCodes from './zipCode';
import * as ocrData from './OCRApi';
import * as orderHistory from './orderHistory';
import * as examOrderDocumentGroup from './examOrderDocumentGroup';
import * as examOrderDocumentFields from './examOrderDocumentFields';
import * as examBlobDocumentFile from './examBlobDocumentFile';
import * as examOrderFinalReview from './examOrderFinalReview';
import * as examOrderVesting from './examOrderVesting';
import * as examOrderLegalDescription from './examOrderLegalDescription';
import * as examOrderReferences from './examOrderDocumentReference';
import * as examOrder from './examOrder';
import * as examOrderParties from './examOrderParties';
import * as examProperty from './examProperty';
import * as exceptionsRequirements from './requirementsExceptions';
import * as examCodeBook from './examCodes';
import * as examUploadDocument from './examOrderUploadDocument';
import * as documentFileTagUntag from './documentFileTagUntag';
import * as documentTagUntag from './documentTagUntag';
import * as examOrderReviewState from './examOrderReviewState';
import * as examOrderSearchReport from './examOrderSearchReport';
import * as examOrderTaxes from './examOrderTaxes';
import * as examOrderUploadTaxDocument from './examOrderUploadTaxDocument';
import * as reswareRemitOrder from './reswareRemitOrder';
import * as examOrderDocumentNotes from './examOrderDocumentNotes';
import * as configManager from './configManager';
export default {
    profile,
    state,
    county,
    orders,
    stateCounty,
    client,
    productTypes,
    projectStatuses,
    documentTypes,
    documentCategories,
    interestEstateTypes,
    businessSegment,
    zipCodes,
    ocrData,
    orderHistory,
    examOrderDocumentGroup,
    examOrderDocumentFields,
    examOrderFinalReview,
    examOrderVesting,
    examOrderLegalDescription,
    examBlobDocumentFile,
    examOrderReferences,
    examOrder,
    examOrderParties,
    examProperty,
    exceptionsRequirements,
    examCodeBook,
    examUploadDocument,
    documentFileTagUntag,
    documentTagUntag,
    examOrderReviewState,
    examOrderSearchReport,
    examOrderTaxes,
    examOrderUploadTaxDocument,
    reswareRemitOrder,
    examOrderDocumentNotes,
    configManager
};
