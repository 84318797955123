import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
// import { TimePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { GridColumnHeaderParams, GridCellParams } from '@mui/x-data-grid';
import { SelectItem } from 'types/propTypes';
import DebouncedInputFloat from 'components/styled/DebouncedInputFloat';
import DebouncedInput from 'components/styled/DebouncedInput';
import MultiSelect from 'components/styled/MultiSelect';
// import DatePicker from 'components/styled/DatePicker';
// import colors from 'core/constants/colors';
import MainSelect from 'components/styled/Select';
import {
    Filter,
    ColumnType,
    UpdateFilterObj,
    ForeignKeyWithMenuItems
} from 'types/configManager';
// import InputAdornment from '@mui/material/InputAdornment';
import { ConfigManagerFieldTypes } from 'core/constants/common';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/**
 * Conditional block that handles creating the appropriate header filter components
 * @param colType
 * @param filterId
 * @param params
 * @param updateFiltersCallback
 * @param type
 * @param foreignKeyMenuItemsObj
 * @returns {JSX.Element}
 */
export const createHeaderComponent = (
    colType: ColumnType,
    filterId: string,
    params?: GridColumnHeaderParams,
    updateFiltersCallback?: (filter: UpdateFilterObj) => void,
    type?: number,
    foreignKeyMenuItemsObj?: ForeignKeyWithMenuItems
) => {
    //TODO: handle the ColumnType.Name case, and create a default handler
    if (colType === ColumnType.FieldDefinition) {
        return determineInputComponent(type, params, updateFiltersCallback, filterId);
    } else if (colType === ColumnType.ForeignKey) {
        // Gotta turn the DropdownItems into SelectItems
        const selArr: SelectItem[] = foreignKeyMenuItemsObj.dropdownMenuItems.items.map(
            (dd) => {
                return {
                    id: dd.id,
                    value: dd.name
                };
            }
        );
        return createDropDownComponent(
            'forKey',
            selArr,
            params.field,
            filterId,
            updateFiltersCallback,
            params.colDef.width
        );
    } else {
        return <>{params.colDef.headerName}</>;
    }
};

/**
 * TODO: create respectice component/functions/file structure for this
 * @param type
 * @param params
 * @param updateFiltersCallback
 * @param filterId
 * @param renderFrom
 * @returns {JSX.Element}
 */
const determineInputComponent = (
    type: number,
    params: GridColumnHeaderParams,
    updateFiltersCallback: (filter: UpdateFilterObj) => void,
    filterId: string
) => {
    let component;
    switch (type) {
        case ConfigManagerFieldTypes.String:
            component = createTextFieldComponent(params, updateFiltersCallback, filterId);
            break;
        // case ConfigManagerFieldTypes.TextArea:
        //     component = createTextAreaComponent();
        //     break;
        // case ConfigManagerFieldTypes.Boolean:
        //     component = createCheckboxComponent();
        //     break;
        case ConfigManagerFieldTypes.Int64:
            component = createIntComponent(params, updateFiltersCallback);
            break;
        case ConfigManagerFieldTypes.Number:
            break;
        case ConfigManagerFieldTypes.Decimal:
            component = createTextFieldComponent(params, updateFiltersCallback, filterId);
            break;
        // case ConfigManagerFieldTypes.Date:
        //     component = createDateOrTimeOrDateTimeComponent();
        //     break;
        // case ConfigManagerFieldTypes.Time:
        //     component = createDateOrTimeOrDateTimeComponent();
        //     break;
        // case ConfigManagerFieldTypes.DateTime:
        //     component = createDateOrTimeOrDateTimeComponent();
        //     break;
        // case ConfigManagerFieldTypes.GuidWOptions:
        //     component = createDropDownComponent();
        //     break;
        // case ConfigManagerFieldTypes.GuidWURI:
        //     component = createDropDownComponent();
        //     break;
        // case ConfigManagerFieldTypes.ForeignKey:
        //     component = createDropDownComponent();
        //     break;
        // case ConfigManagerFieldTypes.JSONObject:
        //     component = createTextAreaComponent();
        //     break;
    }

    return component;
};

export default determineInputComponent;

/**
 * This needs a lot more information, but without full results this shows a basic way to display different types of data
 * @param params
 * @param updateFiltersCallback
 * @param filterId
 *  @returns {JSX.Element} Element
 */
export const createTextFieldComponent = (
    params: GridCellParams | GridColumnHeaderParams,
    updateFiltersCallback: (filter: UpdateFilterObj) => void,
    filterId: string
) => {
    // const [inputVal, setInputValue] = useState('');

    return (
        <DebouncedInput
            label={params.field}
            placeHolder={`Filter on ${params.field}`}
            onChange={(value) => {
                if (value === null) {
                    return;
                }
                const ret: UpdateFilterObj = {
                    filters: [{ id: filterId, value, name: params.field }], //value === '' ? [] :
                    colType: ColumnType.FieldDefinition
                };
                updateFiltersCallback(ret);
            }}
            debounceTime={1000}
            // value={inputVal}
        />
    );
};

/**
 * TODO:
 * Using a Float debounced input to handle the Int type. Just going to cutoff the decimals for
 * presentation
 * @param params
 * @param updateFiltersCallback
 * @returns {JSX.Element}
 */
const createIntComponent = (
    params: GridColumnHeaderParams,
    updateFiltersCallback: (filter: UpdateFilterObj) => void
) => {
    // const [inputVal, setInputValue] = useState(null);
    return (
        <DebouncedInputFloat
            label={params.field}
            placeHolder={`Filter on ${params.field}`}
            onChange={(value) => {
                const ret: UpdateFilterObj = {
                    filters:
                        value === null
                            ? []
                            : [
                                  {
                                      id: params.field,
                                      value: value.toFixed(0),
                                      name: params.field
                                  }
                              ],
                    colType: ColumnType.FieldDefinition
                };
                updateFiltersCallback(ret);
            }}
            config={true}
            value={null}
        />
    );
};

//TODO: This one needs to be modified to show a button and then a text component
// const createTextAreaComponent = () => {
//     console.log('text area');
//     // let temp;
//     // switch (type) {
//     //     case 0: // textarea
//     //         temp = <TextareaAutosize id={field.id} value={'Textarea'} />;
//     //         break;
//     //     case 1: // json
//     //         temp = <TextareaAutosize id={field.id} value={'{JSON: value}'} />;
//     //         break;
//     // }
//     // return temp;
// };
// //TODO:
// const createDateOrTimeOrDateTimeComponent = () => {
//     console.log('date');

//     // switch (type) {
//     //     case 0: // Date
//     //         temp = (
//     //             <DatePicker
//     //                 label={field.fieldName}
//     //                 value={'1999-06-22'}
//     //                 onChange={(e) => window.alert(e)}
//     //             />
//     //         );
//     //         break;
//     //     case 1:
//     //         temp = (
//     //             <LocalizationProvider dateAdapter={AdapterDateFns}>
//     //                 <TimePicker
//     //                     label={field.fieldName}
//     //                     value={'1999-06-22'}
//     //                     onChange={(e) => window.alert(e)}
//     //                     renderInput={(params) => <TextField {...params} />}
//     //                 />
//     //             </LocalizationProvider>
//     //         );
//     //         break;
//     //     case 2:
//     //         temp = (
//     //             <LocalizationProvider dateAdapter={AdapterDateFns}>
//     //                 <DateTimePicker
//     //                     label={field.fieldName}
//     //                     value={'1999-06-22'}
//     //                     onChange={(e) => window.alert(e)}
//     //                     renderInput={(params) => <TextField {...params} />}
//     //                 />
//     //             </LocalizationProvider>
//     //         );
//     //         break;
//     // }
//     // return temp;
// };

// //TODO:
// const createCheckboxComponent = () => {
//     console.log('checbox');
// };

//TODO:
export const createDropDownComponent = (
    from: 'forKey' | 'fieldDef' | 'newRow' | 'row',
    menuItems: SelectItem[],
    fieldName: string,
    filterId: string,
    updateFiltersCallback?: (filter: UpdateFilterObj) => void,
    colWidth?: number
) => {
    const multiSelectHandleChange = (event: SelectChangeEvent<string[]>) => {
        const selected = event.target.value;
        let filArr: Filter[] = [];
        if (selected.includes('All')) {
            setCurrentCheckedItems(menuItems.map((item) => item.value));
        } else {
            setCurrentCheckedItems(selected as string[]);
        }
        filArr = (selected as string[]).map((val) => {
            return { name: fieldName, id: filterId, value: val };
        });
        if (filArr.length === 0) {
            filArr = [{ name: fieldName, id: filterId, value: 'None' }];
        }
        updateFiltersCallback({ filters: filArr, colType: ColumnType.ForeignKey });
    };

    const singleSelectHandleChange = (event: SelectChangeEvent<string>) => {
        setSingleSelctedValue(event.target.value);
    };

    const [currentCheckedItems, setCurrentCheckedItems] = useState<string[]>([]);
    const [singleSelectedValue, setSingleSelctedValue] = useState('');

    switch (from) {
        case 'forKey': // These are multi-select dropwdowns.
            return (
                <MultiSelect
                    selectOnChange={(e) => {
                        multiSelectHandleChange(e);
                    }}
                    selectValue={currentCheckedItems}
                    label={fieldName}
                    menuItems={menuItems}
                    minWidth={colWidth - 30}
                />
            );
        case 'newRow': // These are single-select dropdowns.
            return (
                <MainSelect
                    name={fieldName}
                    id={fieldName}
                    error={singleSelectedValue === ''}
                    helperText={'required'}
                    label={`${fieldName}*`}
                    selectValue={singleSelectedValue}
                    menuItems={menuItems}
                    selectOnChange={singleSelectHandleChange}
                    minWidth={300}
                />
            );
    }
};
