import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import MainButton from 'components/styled/MainButton';
import { ORDERS } from 'core/constants/navigation';
import { textStylesMontserrat } from 'styles/common';
import styles from './styles';

/**
 * Render blank page in case not selected order
 * @component
 *
 * @returns {JSX.Element}
 */
const EmptyOrder = () => {
    const navigate = useNavigate();
    const { root } = styles().classes;
    const { subtitle_16_semibold } = textStylesMontserrat().classes;
    return (
        <Grid className={root} display={'flex'} flexDirection={'column'} gap={'40px'}>
            <Box className={subtitle_16_semibold} color={'#000000'}>
                Welcome to Workbench! <br />
                Looks like you don&apos;t have any order in progress.
            </Box>
            <MainButton
                retainCase={true}
                text={'Go to Dashboard'}
                action={() => navigate(`/${ORDERS}`)}></MainButton>
        </Grid>
    );
};

export default EmptyOrder;
