import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { ProductType } from 'types/dataModels';

interface ProductTypesState {
    productTypes: ProductType[];
}

const initialState: ProductTypesState = {
    productTypes: []
};

const ProductTypesSlice = createSlice({
    name: 'productTypes',
    initialState,
    reducers: {
        setProductTypes(state, action: PayloadAction<ProductType[]>) {
            state.productTypes = action.payload;
        }
    }
});

export const { setProductTypes } = ProductTypesSlice.actions;

/**
 * Fetch prduct types data from BE
 * @returns {AppThunk}
 */
export const fetchProductTypes = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.productTypes.getProductTypes();
        dispatch(setProductTypes(response));
    } catch (err) {
        dispatch(setProductTypes([]));
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get product types: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default ProductTypesSlice.reducer;
