import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MSAL_CONFIG, GTMIDS } from 'core/constants/common';
import ReactGA from 'react-ga';

const msalConfig = JSON.parse(localStorage.getItem(MSAL_CONFIG));
const gtmId: string = GTMIDS?.[msalConfig?.env || 'Development'];

const usePageTracking = (): void => {
    const location = useLocation();

    useEffect(() => {
        if (gtmId) {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        }
    }, [location]);
};

export default usePageTracking;
