import React, { ReactElement, useState, useEffect } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import MsalAuthService from 'core/services/MsalAuthService';

interface MsalInstanceProviderProps {
    children: React.ReactElement;
}

/**
 * Wrapper component for MSAL provider
 * @param {JSX.Element} children
 * @component
 * @returns {JSX.Element}
 */
export default function MsalInstanceProvider({
    children
}: MsalInstanceProviderProps): ReactElement {
    const [msalConfig, setMsalConfig] = useState(null);
    const [msalInstance, setMsalInstance] = useState(null);
    const msalConfigReq = async () => await MsalAuthService.getMsalConfig();
    const [initialLoad, setInitialLoad] = useState(false);

    useEffect(() => {
        if (initialLoad && !msalConfig) {
            msalConfigReq().then((res) => {
                if(!msalInstance){
                    const tempmsalInstance = new PublicClientApplication(res);
                    (async () => {
                        await tempmsalInstance.initialize().then(() => {
                            setMsalInstance(tempmsalInstance);
                            setMsalConfig(res);
                        });
                    })();
                }
            });
        }
    }, [initialLoad]);

    useEffect(() => {
        !initialLoad && setInitialLoad(true);
    }, []);

    return !!msalInstance && <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}
