import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectStatus } from 'types/dataModels';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity, ORDER_STATUSES, ProjectOrderStatus } from 'core/constants/common';
import api from 'core/api';
interface WorkbenchStatusInitialState {
    /**
     * Opens popUp for pause
     */
    pendingPause: boolean;
    /**
     * Pause reason
     */
    pauseReason: string;
    /**
     * Order status
     */
    status: string;
    /**
     * Array of allowed statuses for order
     */
    allowedStatuses: ProjectStatus[];
    /**
     * Read only view of workbench
     */
    readonly: boolean;
    /**
     * override readonly and force editable mode
     */
    forceEditable: boolean;
    /**
     * show order details modal after putting order on hold OR on pause
     */
    isOnHoldOrPauseViewVisible: boolean;
}

const initialState: WorkbenchStatusInitialState = {
    pendingPause: false,
    status: '',
    pauseReason: '',
    allowedStatuses: [],
    readonly: false,
    forceEditable: false,
    isOnHoldOrPauseViewVisible: false
};

const workbenchStatusSlice = createSlice({
    name: 'workbenchStatus',
    initialState,
    reducers: {
        /**
         * Set the status of the current order in Workbench
         * @param state Slice state
         * @param action Payload with the name of status to set
         */
        setWorkbenchStatus(state, action: PayloadAction<string>) {
            if (
                action.payload === ProjectOrderStatus.pause ||
                action.payload === ProjectOrderStatus.InProgressPaused
            ) {
                state.status = action.payload;
                state.pendingPause = true;
            } else if (action.payload === ProjectOrderStatus.onHold) {
                state.status = action.payload;
                state.pendingPause = false;
            } else {
                state.status = action.payload;
                state.pendingPause = false;
                state.pauseReason = '';
            }
        },
        /**
         * Set a reason for paused order state
         * @param state Slice state
         * @param action Payload with the reason for pause
         */
        setWorkbenchPauseReason(state, action: PayloadAction<string>) {
            state.pauseReason = action.payload;
        },
        /**
         * Set the list of statuses that are available for selection
         * @param state Slice state
         * @param action Payload with the list of allowed statuses
         */
        setAllowedStatuses(state, action: PayloadAction<ProjectStatus[]>) {
            state.allowedStatuses = action.payload;
        },
        /**
         * Set read only view of workbench, when assigning an order to another examiner
         * @param state Slice state
         * @param action Payload to change read only state
         */
        setReadOnlyView(state, action: PayloadAction<boolean>) {
            state.readonly = action.payload;
        },
        /**
         * Set read only view of workbench, when assigning an order to another examiner
         * @param state Slice state
         * @param action Payload to change read only state
         */
        setForceEditable(state, action: PayloadAction<boolean>) {
            state.forceEditable = action.payload;
        },
        /**
         * Set order details modal to visible after order is put on hold OR on pause
         * @param state Slice state
         * @param action Payload to change on hold/on pause view to visible or not
         */
        setIsOnHoldOrPauseViewVisible(state, action: PayloadAction<boolean>) {
            state.isOnHoldOrPauseViewVisible = action.payload;
        }
    }
});

export const {
    setWorkbenchStatus,
    setWorkbenchPauseReason,
    setAllowedStatuses,
    setReadOnlyView,
    setForceEditable,
    setIsOnHoldOrPauseViewVisible
} = workbenchStatusSlice.actions;

/**
 * Fetch the allowed statuses for an existing order
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchAllowedStatuses =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orders.getAllowedStatuses(orderId);
            const filteredStatuses = response
                .filter((status) => ORDER_STATUSES.includes(status.displayName))
                .reverse();
            dispatch(setAllowedStatuses(filteredStatuses));
        } catch (err) {
            dispatch(setAllowedStatuses([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get allowed statuses for exam order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };
export default workbenchStatusSlice.reducer;
