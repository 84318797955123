import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import styles from './styles';

export const StyledIconButton = styled(
    forwardRef(function StyledRefIconButton(
        props: IconButtonMainProps,
        ref: React.Ref<HTMLButtonElement>
    ) {
        return <IconButton {...props} ref={ref} />;
    })
)(() => ({
    backgroundColor: '#483D91',
    padding: '6px 7px',
    height: '32px',
    width: '34px',
    '&.Mui-disabled': {
        opacity: '0.5'
    },
    '&.Mui-focusVisible': {
        outline: '2px solid #643BC4',
        outlineOffset: '2px'
    }
}));

export interface IconButtonMainProps extends IconButtonProps {
    id?: string;
    icon?: JSX.Element;
    withoutBackground?: boolean;
    onClick?: React.MouseEventHandler;
    className?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
    withBorder?: boolean;
    borderColor?: string;
    disableRipple?: boolean;
    padding?: string;
    isOutline?: boolean;
}

/**
 * Styled Icon button component
 * @param {string} id Id
 * @param {JSX.Element} icon Icon for displaying as a button
 * @param {boolean} withoutBackground render without background
 * @param {React.MouseEventHandler} onClick onClick handler
 * @param {string} className ClassName value
 * @param {string} width Width value
 * @param {string} height Height value
 * @param {boolean} disabled Is button disabled
 * @param {boolean} withBorder Is render with border
 * @param {string} borderColor Border color value
 * @param {boolean} disableRipple If true, the ripple effect is disabled.
 * @param {string} padding
 * @param {boolean} isOutline if true then outlined icon btn, else simple icon
 * @component
 * @returns {JSX.Element}
 */
const IconButtonMain = forwardRef<HTMLButtonElement, IconButtonMainProps>(
    (
        {
            id,
            icon,
            withoutBackground = false,
            onClick,
            className,
            width,
            height,
            disabled,
            withBorder,
            borderColor,
            disableRipple,
            padding = '0px',
            isOutline = false
        }: IconButtonMainProps,
        forwardedRef: React.Ref<HTMLButtonElement>
    ): JSX.Element => {
        const { darkIconBtnHover, lightIconBtnHover } = styles().classes;

        return (
            <StyledIconButton
                ref={forwardedRef}
                id={id}
                disabled={disabled}
                onClick={onClick}
                className={
                    className
                        ? className
                        : isOutline
                        ? withoutBackground
                            ? lightIconBtnHover
                            : darkIconBtnHover
                        : ''
                }
                disableRipple={disableRipple}
                style={{
                    borderRadius: 0,
                    backgroundColor: withoutBackground ? 'transparent' : '#483D91',
                    padding: padding ? padding : 0,
                    width: width || 'max-content',
                    height: height || 'max-content',
                    border: withBorder ? `1px solid ${borderColor}` : 'none'
                }}>
                {icon}
            </StyledIconButton>
        );
    }
);

IconButtonMain.displayName = 'IconButtonMain';
export default IconButtonMain;
