import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Box,
    Drawer,
    List,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    SvgIcon,
    Button
} from '@mui/material';
import { ORDERS, TEST_ORDERS, TEST_ORDER_FORM, WORKBENCH } from 'core/constants/navigation';
import { SidebarPageNames } from 'core/constants/common';
import { IconPaths } from 'components/styled/Icon';
import BadgeMain from 'components/styled/Badge';
import ProfileCard from './ProfileCard';
import styles from './LeftSidebar.module.scss';

const NOTIFICATION_COUNT = 1;

/**
 * Component for rendering left sidebar menu for navigation through app pages
 * @component
 * @returns {JSX.Element}
 */
function LeftSidebar() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef(null);

    /**
     * Check app route for highlight current tab in sidebar
     * @param {string} route Route name
     * @returns {boolean}
     */
    const isActive = (route: string): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, currentRoute] = location.pathname.split('/');
        return currentRoute === route;
    };

    /**
     * Navigation to selected route
     * @param {string} route Route name
     */
    const onNavigate = (route: string) => {
        navigate(`/${route}`);
        setOpen(false);
    };

    return (
        <section className={styles.sidebarContainer} ref={ref}>
            <Drawer
                onClose={() => {
                    setOpen(false);
                }}
                variant="permanent"
                className={clsx(styles.drawer, {
                    [styles.drawerOpen]: open,
                    [styles.drawerClose]: !open
                })}
                classes={{
                    paper: clsx(styles.paper, {
                        [styles.drawerOpen]: open,
                        [styles.drawerClose]: !open
                    })
                }}>
                <Button
                    className={styles.sidebarToggle}
                    onClick={() => setOpen(!open)}
                    disableRipple>
                    <ArrowRightIcon
                        className={open ? styles['arrowRight--expanded'] : styles.arrowRight}
                    />
                </Button>
                <Box>
                    <Box className={styles.menuTitle}>{open ? 'MENU' : ''}</Box>
                    <List component="nav">
                        <ListItemButton
                            disabled
                            className={clsx(styles.listItem, styles.disabled)}>
                            <ListItemIcon className={styles.listItem__icon}>
                                <SvgIcon viewBox={'-2 -3 24 24'}>
                                    <path d={IconPaths.leaderboardInactive} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={styles.listItem__text}
                                primary={SidebarPageNames.dashboard}
                            />
                        </ListItemButton>

                        <ListItemButton
                            className={clsx(styles.listItem, {
                                [styles.active]: isActive(WORKBENCH)
                            })}
                            onClick={() => onNavigate(`${WORKBENCH}/empty`)}>
                            <ListItemIcon className={styles.listItem__icon}>
                                {isActive(WORKBENCH) ? (
                                    <SvgIcon viewBox={'-3 -5 24 24'}>
                                        <path d={IconPaths.viewQuiltActive} />
                                    </SvgIcon>
                                ) : (
                                    <SvgIcon viewBox={'-3 -5 24 24'}>
                                        <path d={IconPaths.viewQuiltInactive} />
                                    </SvgIcon>
                                )}
                            </ListItemIcon>
                            <ListItemText
                                className={styles.listItem__text}
                                primary={SidebarPageNames.workbench}
                            />
                        </ListItemButton>
                        <ListItemButton
                            className={clsx(styles.listItem, {
                                [styles.active]: isActive(ORDERS)
                            })}
                            onClick={() => onNavigate(ORDERS)}>
                            <ListItemIcon className={styles.listItem__icon}>
                                {isActive(ORDERS) ? (
                                    <SvgIcon viewBox={'-3 -3 24 24'}>
                                        <path d={IconPaths.stickyNoteActive} />
                                    </SvgIcon>
                                ) : (
                                    <SvgIcon viewBox={'-3 -3 24 24'}>
                                        <path d={IconPaths.stickyNoteInactive} />
                                    </SvgIcon>
                                )}
                            </ListItemIcon>
                            <ListItemText
                                className={styles.listItem__text}
                                primary={SidebarPageNames.orders}
                            />
                        </ListItemButton>
                        <ListItemButton
                            disabled
                            className={clsx(styles.listItem, styles.disabled)}>
                            <ListItemIcon className={styles.listItem__icon}>
                                <SvgIcon viewBox={'-2 -2 24 24'}>
                                    <path d={IconPaths.libraryBooksInactive} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={styles.listItem__text}
                                primary={SidebarPageNames.history}
                            />
                        </ListItemButton>
                        <ListItemButton
                            className={clsx(styles.listItem, {
                                [styles.active]: isActive(`${TEST_ORDERS}`)
                            })}
                            onClick={() => onNavigate(`${TEST_ORDERS}/${TEST_ORDER_FORM}`)}>
                            <ListItemIcon className={styles.listItem__icon}>
                                {isActive(`${TEST_ORDERS}`) ? (
                                    <SvgIcon viewBox={'-2.15 0 28 28'}>
                                        <path d={IconPaths.newOrderActive} />
                                    </SvgIcon>
                                ) : (
                                    <SvgIcon viewBox={'-2.15 0 28 28'}>
                                        <path d={IconPaths.newOrderInactive} />
                                    </SvgIcon>
                                )}
                            </ListItemIcon>
                            <ListItemText
                                className={styles.listItem__text}
                                primary={SidebarPageNames.neworder}
                            />
                        </ListItemButton>
                        <ListItemButton
                            disabled
                            className={clsx(styles.listItem, styles.disabled)}>
                            <ListItemIcon className={styles.listItem__icon}>
                                {NOTIFICATION_COUNT ? (
                                    <BadgeMain badgeContent={NOTIFICATION_COUNT}>
                                        <SvgIcon viewBox={'-2 -2 24 24'}>
                                            <path d={IconPaths.smsInactive} />
                                        </SvgIcon>
                                    </BadgeMain>
                                ) : (
                                    <SvgIcon viewBox={'-2 -2 24 24'}>
                                        <path d={IconPaths.smsInactive} />
                                    </SvgIcon>
                                )}
                            </ListItemIcon>
                            <ListItemText
                                className={styles.listItem__text}
                                primary={SidebarPageNames.messages}
                            />
                        </ListItemButton>
                        <ListItemButton
                            disabled
                            className={clsx(styles.listItem, styles.disabled)}>
                            <ListItemIcon className={styles.listItem__icon}>
                                <SvgIcon viewBox={'-2 0 24.5 24.5'}>
                                    <path d={IconPaths.headphonesInactive} />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={styles.listItem__text}
                                primary={SidebarPageNames.support}
                            />
                        </ListItemButton>
                    </List>
                </Box>
                <Box className={styles.profileInfo}>
                    <ProfileCard open={open} />
                </Box>
            </Drawer>
        </section>
    );
}

export default LeftSidebar;
