import { combineReducers } from '@reduxjs/toolkit';
import orderDetailsValidationReducer from 'core/features/validation/orderDetailsValidation/orderDetailsValidationSlice';

const validationReducer = combineReducers({
    inputValidation: combineReducers({
        orderDetailsValidationData: orderDetailsValidationReducer
    })
});

export default validationReducer;
