import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { GlobalStatusFilter, SnackbarSeverity } from 'core/constants/common';
import { PagedData, ProjectOrder } from 'types/dataModels';

export interface OrdersData {
    orders: {
        isFiltersShown: boolean;
        globalStatusFilter: GlobalStatusFilter;
        allOrders: PagedData<ProjectOrder>;
    };
}

const initialState: OrdersData = {
    orders: {
        isFiltersShown: false,
        globalStatusFilter: GlobalStatusFilter.CompletedExcluded,
        allOrders: {
            data: [],
            page: 1,
            pageSize: 20,
            count: 0,
            totalPages: 0,
            totalCount: 0,
            pageLimit: 100
        }
    }
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrders(state, action: PayloadAction<PagedData<ProjectOrder>>) {
            state.orders.allOrders = action.payload;
        },
        setIsFiltersShown(state, action: PayloadAction<boolean>) {
            state.orders.isFiltersShown = action.payload;
        },
        setGlobalStatusFilter(state, action: PayloadAction<GlobalStatusFilter>) {
            state.orders.globalStatusFilter = action.payload;
        }
    }
});

export const { setOrders, setIsFiltersShown, setGlobalStatusFilter } = ordersSlice.actions;

/**
 * Fetch paged list of orders from BE
 * @param {number} pageNumber Number of the page to fetch orders from
 * @param {number} pageSize Number of the page to fetch orders from
 * @param {number[]} stateIds IDs of the states to filter orders by
 * @param {number[]} countyIds IDs of the counties to filter orders by
 * @param {number[]} businessSegmentIds IDs of the business segments to filter orders by
 * @param {number[]} productTypeIds IDs of the product types to filter orders by
 * @param {number[]} clientIds IDs of the clients to filter orders by
 * @param {string[]} statusIds IDs of the statuses to filter orders by
 * @param {string} search String that represents the user search keywords to filter orders by
 * @param {object} sorting Object that describes the sorting of the order list
 * @param {string} fromDate ISO date string to filter from date
 * @param {string} toDate ISO date string to filter to date
 * @returns {AppThunk}
 */
export const fetchPagedOrders =
    (
        pageNumber: number,
        pageSize: number,
        stateIds?: number[],
        countyIds?: number[],
        businessSegmentIds?: number[],
        productTypeIds?: number[],
        clientIds?: number[],
        statusIds?: string[],
        search?: string,
        sorting?: {
            fieldSorting: { name: string; direction: 0 | 1 }[];
        },
        fromDate?: string,
        toDate?: string
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orders.getPagedOrdersDataApi({
                page: pageNumber,
                pageSize,
                stateIds,
                countyIds,
                businessSegmentIds,
                productTypeIds,
                clientIds,
                statusIds,
                search,
                sorting,
                fromDate,
                toDate
            });
            dispatch(setOrders(response));
        } catch (err) {
            dispatch(setOrders(initialState.orders.allOrders));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get project orders: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Start the exam of an existing order
 * @param {string} orderId ID of the order to start exam
 * @returns {AppThunk}
 */
export const startExamThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.orders.startExam(orderId);
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Start exam order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default ordersSlice.reducer;
