// import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import api from 'core/api';
import { DynamicConfigFilterDto, PagedDataResponse } from 'types/configManager';
import { generateRowsForDataGrid } from 'core/helpers/generateDataGrid';
// , ColumnFilters, Filter, Sorter
/**
 * QueryFn
 * @param configFilter
 * @returns {Promise<ConfigDataRow>}
 */
export const getConfigModelData = async (
    configFilter: DynamicConfigFilterDto
): Promise<PagedDataResponse> => {
    const { modelId, page } = configFilter;
    if (modelId === '' || modelId === null) return null;

    const newPage = page + 1;
    const response = await api.configManager.getPagedCategoryDefinitionResults({
        ...configFilter,
        page: newPage
    });
    const newResponse = {
        ...response,
        data: generateRowsForDataGrid(response.data, response.configCategory)
    };
    return newResponse;
};

const useGridRowQuery = (configFilter: DynamicConfigFilterDto) => {
    const gridRowQuery = useQuery({
        queryKey: [configFilter],
        queryFn: () => getConfigModelData(configFilter),
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    return { gridRowQuery };
};

export default useGridRowQuery;
