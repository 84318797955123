import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { PulseOrderEventDto } from 'types/dto/orderHistoryDto';

/**
 * Get order history for current order
 * @function
 * @category API
 * @param {string} orderId - Current order id value
 * @returns {PulseOrderEventDto[]}
 */
export const getOrderHistoryApi = async (orderId: string): Promise<PulseOrderEventDto[]> => {
    try {
        const response = await api.get<PulseOrderEventDto[]>(`/api/orderHistory/${orderId}`);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
