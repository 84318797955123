import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { IItems, IOCRResult, IOCRResultsData } from 'types/dataModels';

export interface OcrDataState {
    ocrData: IOCRResult[];
    itemsData: IItems[];
    aliasesData: {};
    pagesData: string[];
}

const initialState: OcrDataState = {
    ocrData: [],
    itemsData: [],
    aliasesData: {},
    pagesData: []
};

export const ocrDataSlices = createSlice({
    name: 'ocrData',
    initialState,
    reducers: {
        setOcrData(state, action: PayloadAction<IOCRResultsData>) {
            state.itemsData = action.payload.itemsData;
            state.aliasesData = action.payload.aliasesData;
            state.ocrData = action.payload.ocrData;
            state.pagesData = action.payload.pagesData;
        }
    }
});

export const { setOcrData } = ocrDataSlices.actions;

/**
 * Fetch OCR data for a file from BE
 * @param {string} fileName Name of the file
 * @returns {AppThunk}
 */
export const fetchOcrData =
    (fileName: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.ocrData.OCRApi(fileName);
            dispatch(setOcrData(response));
        } catch (err) {
            dispatch(setOcrData(null));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get OCR data: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default ocrDataSlices.reducer;
