import axios from 'axios';
import { MSAL_CONFIG } from 'core/constants/common';
import { Configuration, LogLevel } from '@azure/msal-browser';

export interface AppConfig {
    USE_API: boolean;
    USE_ENV_VAR: boolean;
    API_URL: string;
    REDIRECT_URL: string;
    CLIENT_ID: string;
    AUTHORITY: string;
    RESET_PASSWORD: string;
    EDIT_PROFILE: string;
    KNOWN_AUTHORITY: string;
    WEB_SCOPE: string;
    API_SCOPE: string;
    ENV: string;
}

async function getAppConfig(): Promise<AppConfig> {
    try {
        const resp = await axios.get<AppConfig>('/configuration/config.json');
        const appConfig = resp.data;

        if (appConfig.USE_API) {
            const apiConfigResp = await axios.get<AppConfig>('/api/configuration');
            return apiConfigResp.data;
        }

        if (appConfig.USE_ENV_VAR) {
            return {
                API_URL: process.env.REACT_APP_API_URL,
                REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
                CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
                AUTHORITY: process.env.REACT_APP_AUTHORITY,
                RESET_PASSWORD: process.env.REACT_APP_RESET_PASSWORD,
                EDIT_PROFILE: process.env.REACT_APP_EDIT_PROFILE,
                KNOWN_AUTHORITY: process.env.REACT_APP_KNOWN_AUTHORITY,
                WEB_SCOPE: process.env.REACT_APP_WEB_SCOPE,
                API_SCOPE: process.env.REACT_APP_API_SCOPE,
                USE_API: false,
                USE_ENV_VAR: true,
                ENV: process.env.ENV
            };
        }

        return appConfig;
    } catch (err) {
        throw new Error(err.message);
    }
}

const MsalAuthService: {
    getMsalConfig: () => Promise<Configuration>;
    getLoginRequestParams: () => Promise<{ scopes: string[] }>;
} = {
    getMsalConfig: async () => {
        const appConfig = await getAppConfig();
        localStorage.setItem(
            MSAL_CONFIG,
            JSON.stringify({
                apiUrl: appConfig.API_URL,
                redirectUri: appConfig.REDIRECT_URL,
                env: appConfig.ENV,
                scopes: ['offline_access', 'openid', appConfig.WEB_SCOPE]
            })
        );

        return {
            auth: {
                clientId: appConfig.CLIENT_ID,
                authority: appConfig.AUTHORITY,
                knownAuthorities: [appConfig.KNOWN_AUTHORITY],
                redirectUri: appConfig.REDIRECT_URL
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {
                loggerOptions: {
                    loggerCallback: (
                        level: LogLevel,
                        message: string,
                        containsPii: boolean
                    ) => {
                        if (containsPii) {
                            return;
                        }
                    }
                },
                apiScope: appConfig.API_SCOPE,
                apiUrl: appConfig.API_URL
            }
        };
    },
    getLoginRequestParams: async () => {
        const appConfig: AppConfig = await getAppConfig();
        return {
            scopes: ['offline_access', 'openid', appConfig.WEB_SCOPE]
        };
    }
};

export default MsalAuthService;
