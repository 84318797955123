import { Checkbox, FormControlLabel } from '@mui/material';
import colors from 'core/constants/colors';
import { FormControlLabelProps } from 'types/propTypes';
import { IconSizes } from 'core/constants/common';
import { Icon, IconPaths } from 'components/styled/Icon';
import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';

const CheckBoxElement = styled(FormControlLabel, {
    shouldForwardProp: (prop) => prop !== 'hasLabel'
})<FormControlLabelProps>(({ hasLabel, size, codebook }) => ({
    marginRight: hasLabel ? '15px' : '0px', // Styling based on custom props
    marginLeft: '0px',
    '&.MuiFormControlLabel-root': {
        width: hasLabel ? 'auto' : size === IconSizes.small ? '12.8px' : '16px'
    },
    '& .MuiCheckbox-root': {
        padding: '0px',
        '&.Mui-focusVisible': {
            outline: '2px solid #643BC4',
            outlineOffset: '-2px',
            borderRadius: 0,
            backgroundColor: colors.main.accentHighlight15
        }
    },
    '& .MuiFormControlLabel-label': {
        paddingLeft: '6px',
        color: codebook ? 'white' : colors.neutral.neutralMid
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
        color: codebook ? 'white' : colors.main.accent
    },
    '.Mui-disabled': {
        color: `${colors.neutral.neutralMid}80`
    },
    '& .Mui-disabled.Mui-checked + .MuiFormControlLabel-label': {
        color: codebook ? 'white' : `${colors.main.accent}80`
    }
}));

const InputLabel = styled('span')({
    width: '16px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto'
});

interface CheckboxMainProps {
    label?: string;
    size?: IconSizes;
    value: string[] | string | number;
    checked: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    codebook?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Styled checkbox component
 * @param {string} label Label value
 * @param {IconSizes} size Checkbox size
 * @param {string[] | string | number} value Value
 * @param {boolean} checked Is checked value
 * @param {boolean} indeterminate Show indeterminate checkbox
 * @param {boolean} disabled disabled value
 * @param {boolean} codebook
 * @param {Function} onChange Change handler
 * @component
 * @returns {JSX.Element}
 */
const CheckboxMain = forwardRef<HTMLButtonElement, CheckboxMainProps>(
    ({ label, size, value, checked, indeterminate, disabled, codebook, onChange }, ref) => {
        return (
            <CheckBoxElement
                codebook={codebook ? 1 : 0}
                hasLabel={!!label}
                label={<InputLabel>{label}</InputLabel>}
                value={value}
                size={size}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                ref={ref}
                control={
                    <Checkbox
                        icon={
                            <Icon
                                path={IconPaths.uncheckedBox}
                                fill={
                                    codebook
                                        ? 'white'
                                        : disabled
                                        ? `${colors.neutral.neutralMid}80`
                                        : `${colors.neutral.neutralMid}`
                                }
                                width={size === IconSizes.small ? 12.8 : 16}
                                height={size === IconSizes.small ? 12.8 : 16}
                                viewBox={'0 0 16 16'}
                            />
                        }
                        checkedIcon={
                            <Icon
                                path={IconPaths.checkedBox}
                                fill={
                                    codebook
                                        ? 'white'
                                        : disabled
                                        ? `${colors.main.accent}80`
                                        : `${colors.main.accent}`
                                }
                                width={size === IconSizes.small ? 12.8 : 16}
                                height={size === IconSizes.small ? 12.8 : 16}
                                viewBox={'0 0 16 16'}
                            />
                        }
                        indeterminateIcon={
                            <Icon
                                path={IconPaths.indeterminateBox}
                                fill={
                                    codebook
                                        ? 'white'
                                        : disabled
                                        ? `${colors.main.accent}80`
                                        : `${colors.main.accent}`
                                }
                                width={size === IconSizes.small ? 12.8 : 16}
                                height={size === IconSizes.small ? 12.8 : 16}
                                viewBox={'0 0 16 16'}
                            />
                        }
                        indeterminate={indeterminate}
                    />
                }
            />
        );
    }
);
CheckboxMain.displayName = 'CheckboxMain';
export default CheckboxMain;
