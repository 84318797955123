import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '7px',
        backgroundColor: theme.palette.common.black,
        borderRadius: 0,
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px'
    }
}));

export interface TooltipMainProps {
    title: string;
    children: ReactElement;
    placement?: TooltipProps['placement'];
}

/**
 * Styled tooltip component
 * @param {string} placement Tooltip placement
 * @param {string} title Tooltip text
 * @param {ReactElement} children Child component
 * @component
 * @returns {JSX.Element}
 */
const TooltipMain = ({ placement = 'top', title, children }: TooltipMainProps) => {
    return (
        <StyledTooltip title={title} placement={placement}>
            {children}
        </StyledTooltip>
    );
};

export default TooltipMain;
