import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    OrderDetailsErrorData,
    PropertyDetailsErrorsData,
    GeneralInfoErrorsData
} from 'types/dataModels';

/**
 * Slices should be grouped by models in which they are saved and stored. The exception to this
 * would be grouping, so if there is a grouping of fields on the frontend that are not all saved
 * in the same model, then we will need to go about that specific use case differently
 */

interface OrderDetailsValidationState {
    orderDetailsErrorsData: OrderDetailsErrorData;
}

const emptyError = {
    feWarning: '',
    beWarning: ''
};

const orderDetails: OrderDetailsErrorData = {
    propertyInfoErrorData: [],
    generalInfoErrorsData: {
        businessSegmentError: emptyError,
        productTypeError: emptyError
    }
};

const initialState: OrderDetailsValidationState = {
    orderDetailsErrorsData: orderDetails
};

const orderDetailsValidationSlice = createSlice({
    name: 'OrdDetailsValidationForm',
    initialState,
    reducers: {
        /**
         * Will set the initial state of input fields on the workbench
         * @param state Slice state
         * @param action Payload with errors data to set
         */
        setOrderDetailsValidationErrors(
            state,
            action: PayloadAction<{
                propertyPanelId: number;
                propertyInfoErrorData: PropertyDetailsErrorsData;
                generalInfoErrorsData: GeneralInfoErrorsData;
            }>
        ) {
            const { propertyPanelId, propertyInfoErrorData, generalInfoErrorsData } =
                action.payload;
            state.orderDetailsErrorsData.propertyInfoErrorData[propertyPanelId] = {
                ...propertyInfoErrorData
            };
            state.orderDetailsErrorsData.generalInfoErrorsData = { ...generalInfoErrorsData };
        },
        /**
         * Reset the input field errors and required fields back to the initial state
         * @param state Slice state
         */
        clearOrderDetailsValidationErrors(state) {
            state.orderDetailsErrorsData = initialState.orderDetailsErrorsData;
        }
    }
});

export const { setOrderDetailsValidationErrors, clearOrderDetailsValidationErrors } =
    orderDetailsValidationSlice.actions;

export default orderDetailsValidationSlice.reducer;
