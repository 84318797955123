import { useState } from 'react';
import { Box } from '@mui/material';
import StewartLogo from 'assets/logos/stewartlogo.svg';
import IconButton from 'components/styled/IconButton';
import { Icon, IconPaths } from 'components/styled/Icon';
import BadgeMain from 'components/styled/Badge';
import colors from 'core/constants/colors';
import styles from './Header.module.scss';

/**
 * Component for rendering app header
 * @component
 * @returns {JSX.Element}
 */
function AppHeader() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [notificationCount, setNotificationCount] = useState(1);

    return (
        <header className={styles.header}>
            <Box className={styles.logoBlock}>
                <Box
                    component={'img'}
                    className={styles.logo}
                    src={StewartLogo}
                    alt={'Stewart Title logo'}></Box>
            </Box>
            <div className={styles.settingsBlock}>
                <Box id={styles.settings}>
                    <IconButton
                        withoutBackground={true}
                        icon={
                            <Icon
                                path={IconPaths.cog}
                                fill={colors.neutral.neutralMid}
                                width={20}
                                height={20}
                                viewBox={'0 0 20 20'}
                            />
                        }
                    />
                </Box>
                <Box id={styles.notifications}>
                    {notificationCount ? (
                        <BadgeMain badgeContent={notificationCount}>
                            <IconButton
                                withoutBackground={true}
                                icon={
                                    <Icon
                                        path={IconPaths.notification}
                                        fill={colors.neutral.neutralMid}
                                        width={21.5}
                                        height={21.5}
                                        viewBox={'0 0 18 21.5'}
                                    />
                                }
                            />
                        </BadgeMain>
                    ) : (
                        <IconButton
                            withoutBackground={true}
                            icon={
                                <Icon
                                    path={IconPaths.notification}
                                    fill={colors.neutral.neutralMid}
                                    width={21.5}
                                    height={21.5}
                                    viewBox={'0 0 18 21.5'}
                                />
                            }
                        />
                    )}
                </Box>
            </div>
        </header>
    );
}

export default AppHeader;
