import { useMutation } from '@tanstack/react-query';
import api from 'core/api';
import { NewRowEntry } from 'types/configManager';

const addNewDynamicConfigRow = async (newConfig: NewRowEntry) => {
    const response = await api.configManager.addNewDynamicConfig(newConfig);
    return response;
};

const useAddNewDynamicConfigMutation = () => {
    const addNewDynamicConfig = useMutation({
        mutationFn: async (newConfig: NewRowEntry) => addNewDynamicConfigRow(newConfig)
    });

    return { addNewDynamicConfig };
};

export default useAddNewDynamicConfigMutation;
