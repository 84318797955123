import { useState, Suspense } from 'react';
import { Box, Grid } from '@mui/material';
import ConfigHeader from 'pages/ConfigManager/ConfigHeader';
import colors from 'core/constants/colors';
import ConfigManagerDataTable from 'pages/ConfigManager/ConfigManagerDataTable/ConfigManagerDataTable';

const ConfigTable = () => {
    const [categoryId, setCategoryId] = useState('');
    const [isFilterOn, setIsFilterOn] = useState(false);
    const [addRowModalOpen, setAddRowModalOpen] = useState(false);

    return (
        <section>
            <Box
                sx={{
                    display: 'sticky',
                    top: 0
                }}>
                <Grid
                    flexDirection={'column'}
                    sx={{
                        padding: '10px 15px 10px 19px',
                        zIndex: 1,
                        backgroundColor: colors.main.primaryLight
                    }}>
                    <Suspense fallback={<div>... Loading</div>}>
                        <ConfigHeader
                            categoryId={categoryId}
                            setCategoryId={setCategoryId}
                            isFilterOn={isFilterOn}
                            setIsFilterOn={setIsFilterOn}
                            setIsRowModalOpen={setAddRowModalOpen}
                        />
                        <ConfigManagerDataTable
                            categoryId={categoryId}
                            isFilterOn={isFilterOn}
                            isAddRowModalOpen={addRowModalOpen}
                            setIsRowModalOpen={setAddRowModalOpen}
                        />
                    </Suspense>
                </Grid>
            </Box>
        </section>
    );
};

export default ConfigTable;
