import { useMutation } from '@tanstack/react-query'; //, useQueryClient
import api from 'core/api';
import { cdResult } from 'types/configManager';

const updateSingleCellValue = async (modelId: string, configId: string, body: cdResult) => {
    const response = await api.configManager.updateDynamicConfig(modelId, configId, body);
    return response;
};

const useSingleCellUpdateMutation = (modelId: string) => {
    const updateCellMutation = useMutation({
        mutationFn: async (data: { configId: string; body: cdResult }) =>
            updateSingleCellValue(modelId, data.configId, data.body)
    });

    return { updateCellMutation };
};

export default useSingleCellUpdateMutation;
