import { CircularProgress, Grid, Typography } from '@mui/material';
import colors from 'core/constants/colors';
import { textStylesRoboto } from 'styles/common';

/**
 * Inline Loading Spinner component
 * @component
 * @returns {JSX.Element}
 */
const InlineLoadingSpinner = () => {
    const { body_14_regular, body_large_16_semibold } = textStylesRoboto().classes;
    return (
        <Grid
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                top: '40%',
                left: '40%',
                height: '100%',
                width: '100%',
                padding: '25px',
                background: colors.main.primaryLight
            }}>
            <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                <Typography
                    className={body_large_16_semibold}
                    color={colors.main.secondaryDark}>
                    Please wait
                </Typography>
            </Grid>
            <Grid
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                sx={{ paddingTop: '10px' }}>
                <Typography textAlign={'center'} className={body_14_regular}>
                    Loading process
                </Typography>
            </Grid>
            <Grid paddingTop={'30px'}>
                <CircularProgress color="secondary" />
            </Grid>
        </Grid>
    );
};

export default InlineLoadingSpinner;
