import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Alert, Snackbar } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import MsalInstanceProvider from 'components/common/MsalInstanceProvider';
import AuthorizedView from 'containers/AuthorizedView';
import UnauthorizedView from 'containers/UnauthorizedView';
import { SnackbarCloseReason } from 'core/constants/common';
import { setSnackbarClose } from 'core/features/snackbar/snackbarSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { ReactElement, SyntheticEvent, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'core/services/QueryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function App(): ReactElement {
    /** React-query global Query Client */

    const dispatch = useAppDispatch();
    const { open, message, autoHideDuration, severity, anchorOrigin, alertVariant } =
        useAppSelector((state) => state.snackbarData);

    /**
     * Callback fired when the component requests to be closed.
     * @param {SyntheticEvent | Event} event
     * @param {string} reason
     */
    const snackbarOnCloseHandler = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === SnackbarCloseReason.Clickaway) {
            return;
        }
        dispatch(setSnackbarClose());
    };

    return (
        <StyledEngineProvider injectFirst>
            <MsalInstanceProvider>
                <>
                    <AuthenticatedTemplate>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools />
                            <Toaster />
                            <div className="content">
                                <AuthorizedView />
                            </div>
                        </QueryClientProvider>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <UnauthorizedView />
                    </UnauthenticatedTemplate>
                </>
            </MsalInstanceProvider>
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={snackbarOnCloseHandler}
                anchorOrigin={anchorOrigin}>
                <Alert
                    variant={alertVariant}
                    onClose={snackbarOnCloseHandler}
                    severity={severity}
                    sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </StyledEngineProvider>
    );
}
export default App;
