import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { ZipCode } from 'types/dataModels';

interface ZipCodesState {
    zipCodes: ZipCode[];
}

const initialState: ZipCodesState = {
    zipCodes: []
};

const zipCodesSlice = createSlice({
    name: 'zipCodes',
    initialState,
    reducers: {
        /**
         * Set zip codes data to store
         * @param state Slice state
         * @param action Payload with the list of zip codes to set
         */
        setZipCodes(state, action: PayloadAction<ZipCode[]>) {
            state.zipCodes = action.payload;
        }
    }
});

export const { setZipCodes } = zipCodesSlice.actions;

/**
 * Fetch zip codes data from BE
 * @param {number} countyId ID of the county
 * @param {number} stateId ID of the state
 * @returns {AppThunk}
 */
export const fetchZipCodes =
    (countyId: number, stateId: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.zipCodes.getZipCodesApi(countyId, stateId);
            dispatch(setZipCodes(response));
        } catch (err) {
            dispatch(setZipCodes([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get zip codes: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Fetch a zip code by ID
 * @param {WorkbenchTabsEnum} zipCodeId ID of the zip code
 * @returns {AppThunk}
 */
export const fetchZipCodeById =
    (zipCodeId: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.zipCodes.getZipCodesByIdApi(zipCodeId);
            dispatch(setZipCodes([response]));
        } catch (err) {
            dispatch(setZipCodes([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get zip code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default zipCodesSlice.reducer;
