import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { Client } from 'types/dataModels';

interface ClientState {
    client: Client[];
}

const initialState = {
    client: []
} as ClientState;

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        /**
         * Set a list of clients to store
         * @param state Slice state
         * @param action Payload with Clients to set
         */
        setClient(state, action: PayloadAction<Client[]>) {
            state.client = action.payload;
        }
    }
});

export const { setClient } = clientSlice.actions;

/**
 * Fetch clients and save response data to store
 * @returns {AppThunk}
 */
export const fetchClient = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.client.getClient();
        dispatch(setClient(response));
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get clients array: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default clientSlice.reducer;
