import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { NewTaxDocument, Tax } from 'types/taxes';

/**
 * Get all taxes documents for current exam order
 * @function
 * @category API
 * @param {string} orderId - Current exam order id
 * @returns {Tax[]}
 */
export const getExamOrderTaxes = async (orderId: string): Promise<Tax[]> => {
    try {
        const response = await api.get<Tax[]>(`/api/examOrder/${orderId}/document/taxes`);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update data on target tax document
 * @function
 * @category API
 * @param {string} orderId - Current exam order id
 * @param {string} documentId - Target tax document
 * @param {NewTaxDocument} data - New tax data object
 * @returns {Tax}
 */
export const updateExamOrderTaxDocument = async (
    orderId: string,
    documentId: string,
    data: NewTaxDocument
): Promise<Tax> => {
    try {
        const response = await api.put<NewTaxDocument, Tax>(
            `/api/examOrder/${orderId}/document/${documentId}/tax`,
            data
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};
