import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { SearchReport } from 'types/searchReport';

interface ExamOrderSearchReportState {
    examOrderSearchReport: SearchReport;
}

const initialState: ExamOrderSearchReportState = {
    examOrderSearchReport: {} as SearchReport
};

const examOrderSearchReportSlice = createSlice({
    name: 'examOrderSearchReport',
    initialState,
    reducers: {
        /**
         * Set search report data from BE to state
         * @param state Slice state
         * @param action Payload with Search Report data to set
         */
        setExamOrderSearchReportData(
            state: ExamOrderSearchReportState,
            action: PayloadAction<SearchReport>
        ) {
            state.examOrderSearchReport = action.payload;
        },
        /**
         * Set search report warnings data from BE to state
         * @param state Slice state
         * @param action Payload with Search Report warnings data to set
         */
        updateExamOrderSearchReportWarningsData(
            state: ExamOrderSearchReportState,
            action: PayloadAction<SearchReport['warnings']>
        ) {
            state.examOrderSearchReport.warnings = action.payload;
        }
    }
});

export const { setExamOrderSearchReportData, updateExamOrderSearchReportWarningsData } =
    examOrderSearchReportSlice.actions;

/**
 * Fetch exam order search report data from BE
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchExamOrderSearchReportData =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderSearchReport.getExamOrderSearchReport(orderId);
            dispatch(setExamOrderSearchReportData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get exam order search report data: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default examOrderSearchReportSlice.reducer;
