import { handleError } from 'core/helpers/errorHandler';
import { Profile } from 'types/dataModels';
import api from '../api';

/**
 * Get user profile data
 * @function
 * @category API
 * @returns {Profile}
 */
export const getProfileDataApi = async (): Promise<Profile> => {
    try {
        const response = await api.getWithCache<Profile>(`/api/me/web`);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
