import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILegalEditorFullScreenSlice {
    /**
     * Full screen view of legal editor
     */
    isLegalFullScreen: boolean;
    legalEditorInitialValue: string;
}

const initialState: ILegalEditorFullScreenSlice = {
    isLegalFullScreen: false,
    legalEditorInitialValue: ''
};

const legalEditorEditorExtrasSlice = createSlice({
    name: 'legalEditorFullScreen',
    initialState,
    reducers: {
        /**
         * Set full screen view of legal editor
         * @param state Slice state
         * @param action payload with the isFullScree value to set
         */
        setLegalIsFullScreen(
            state: ILegalEditorFullScreenSlice,
            action: PayloadAction<ILegalEditorFullScreenSlice['isLegalFullScreen']>
        ) {
            state.isLegalFullScreen = action.payload;
        },
        /**
         * Set the value of the full screen editor
         * @param state Slice state
         * @param action payload with the string value to set
         */
        setLegalInitialValue(
            state: ILegalEditorFullScreenSlice,
            action: PayloadAction<ILegalEditorFullScreenSlice['legalEditorInitialValue']>
        ) {
            state.legalEditorInitialValue = action.payload;
        }
    }
});

export const { setLegalIsFullScreen, setLegalInitialValue } =
    legalEditorEditorExtrasSlice.actions;
export default legalEditorEditorExtrasSlice.reducer;
