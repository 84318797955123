import { handleError } from 'core/helpers/errorHandler';
import {
    ExamOrderKeyDocumentGroupType,
    ExamOrderParentSuccessorDocumentGroupType,
    ExamOrderSearchPackageGroupType
} from 'types/dataModels';
import api from '../api';

/**
 * It gets the exam order document group
 * @function
 * @category API
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId - The id of the order
 * @returns {ExamOrderKeyDocumentGroupType[]}
 */
export const getExamOrderDocumentGroup = async (
    orderId: string
): Promise<ExamOrderKeyDocumentGroupType[]> => {
    try {
        const response = await api.get<ExamOrderKeyDocumentGroupType[]>(
            `/api/examOrder/${orderId}/document/group/key`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It gets the exam order document group with parent and successtor relationships
 * @function
 * @category API
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId - The id of the order
 * @returns {ExamOrderParentSuccessorDocumentGroupType[]}
 */
export const getExamOrderParentSuccessorDocumentGroup = async (
    orderId: string
): Promise<ExamOrderParentSuccessorDocumentGroupType[]> => {
    try {
        const response = await api.get<ExamOrderParentSuccessorDocumentGroupType[]>(
            `/api/examOrder/${orderId}/document/group/keyParentChild`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It gets the exam order search package group
 * @function
 * @category API
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId - string -The id of the order
 * @returns {ExamOrderSearchPackageGroupType[]}
 */
export const getExamOrderSearchPackageGroup = async (
    orderId: string
): Promise<ExamOrderSearchPackageGroupType[]> => {
    try {
        const response = await api.get<ExamOrderSearchPackageGroupType[]>(
            `/api/examOrder/${orderId}/document/group/searchPackage`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
