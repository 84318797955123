import api from 'core/api';
import { useQuery } from '@tanstack/react-query'; // V4 which is Dependent on React v17, can use V5 with react update
import { generateColumnStructure } from 'core/helpers/generateDataGrid';
import { UpdateFilterObj } from 'types/configManager';

/**
 * This is going to be the joint QueryFN that returns the dataGrid data structure to create the grid
 * @param id
 * @param updateFiltersCallback
 * @param deleteRowCallback
 * @returns { Promise<ConfigManagerDataGrid> }
 */
export const getCategoryDefinitionColumns = async (
    id: string,
    updateFiltersCallback: (filter: UpdateFilterObj) => void,
    deleteRowCallback: (configId: string) => void
) => {
    if (id === null || id === '') return null;

    const columnsDefinitions = await api.configManager.getCategoryModelApi(id);
    // We will return a type that defines the cache instance for this category
    return await generateColumnStructure(
        columnsDefinitions,
        updateFiltersCallback,
        deleteRowCallback
    );
};

const useGridColumnQuery = (
    id: string,
    updateFiltersCallback: (filter: UpdateFilterObj) => void,
    deleteRowCallback: (configId: string) => void
) => {
    const gridColumnQuery = useQuery({
        queryKey: ['category', id],
        queryFn: () =>
            getCategoryDefinitionColumns(id, updateFiltersCallback, deleteRowCallback),
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false
    });

    return { gridColumnQuery };
};

export default useGridColumnQuery;
