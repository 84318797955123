import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { PulseOrderEventDto } from 'types/dto/orderHistoryDto';

interface OrderHistoryState {
    orderHistory: PulseOrderEventDto[];
    isNewestFirst: boolean;
}

const initialState: OrderHistoryState = {
    orderHistory: [],
    isNewestFirst: true
};

const orderHistorySlice = createSlice({
    name: 'orderHistory',
    initialState,
    reducers: {
        setOrderHistory(state, action: PayloadAction<PulseOrderEventDto[]>) {
            state.orderHistory = action.payload;
        },
        setIsNewestFirst(state, action: PayloadAction<boolean>) {
            state.isNewestFirst = action.payload;
        }
    }
});

/**
 * Fetch order history data from BE
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchOrderHistory =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orderHistory.getOrderHistoryApi(orderId);
            dispatch(setOrderHistory(response));
        } catch (err) {
            dispatch(setOrderHistory([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get order history: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const { setOrderHistory, setIsNewestFirst } = orderHistorySlice.actions;

export default orderHistorySlice.reducer;
