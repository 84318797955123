import { Box, Grid, Stack, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { Icon, IconPaths } from 'components/styled/Icon';
import IconButton from 'components/styled/IconButton';
import colors from 'core/constants/colors';
import { useAppSelector } from 'core/hooks/useAppSelector';
import styles from './ProfileCard.module.scss';
import { useState } from 'react';

export interface ProfileCardProps {
    open: boolean;
}

/**
 * Profile card section on sidebar menu
 * @param {boolean} open Expanded/collapsed view
 * @component
 * @returns {JSX.Element}
 */
export default function ProfileCard({ open }: ProfileCardProps) {
    const { profile } = useAppSelector((state) => state.profileData);
    const { instance, inProgress } = useMsal();
    const [logoutInProgress, setLogoutInProgress] = useState(false);

    const getInitials = (): string =>
        profile?.name
            ?.split(' ')
            .splice(0, 2)
            .map((string) => string[0])
            .join('') || '';

    const handleLogout = async () => {
        if (inProgress == InteractionStatus.None && !logoutInProgress) {
            setLogoutInProgress(true);
            await instance.logoutRedirect();
        }
    };

    return (
        <Box className={styles.profileCard}>
            <Box className={styles.menuTitle}>{open ? 'PROFILE' : ''}</Box>
            <Grid className={styles.userRow}>
                <Box className={styles.profilePic}>
                    <div className={styles.initials}>
                        <span>{getInitials() || ''}</span>
                    </div>
                    <div className={styles.onlineBadge}></div>
                </Box>
                <Box className={styles.expandedControls}>
                    <Stack className={styles.userData}>
                        <Box className={styles.userName}>{profile?.name}</Box>
                        <Box className={styles.userRole}>Examiner</Box>
                    </Stack>
                    <Box className={styles.dropdownButton}>
                        <IconButton
                            withoutBackground={true}
                            icon={<KeyboardArrowDownIcon className={styles.arrowDown} />}
                        />
                    </Box>
                </Box>
            </Grid>
            <Button className={styles.logOutBtn} onClick={handleLogout} disableRipple>
                {open && (
                    <Box component={'span'} className={styles.logOutText}>
                        Log out
                    </Box>
                )}
                <Box
                    component={'span'}
                    className={open ? styles['logOutIcon--expanded'] : styles.logOutIcon}>
                    <Icon
                        path={IconPaths.logOut}
                        fill={colors.main.primaryLight}
                        height={20}
                        width={20}
                        viewBox={'0 -2 20 20'}
                    />
                </Box>
            </Button>
        </Box>
    );
}
