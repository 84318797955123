// @ts-nocheck
import { BroadcastChannel } from 'broadcast-channel';
import { isWorkbenchPage } from 'core/helpers/workbenchHelpers';

let lastUuid = 0;

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
function guid() {
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const WINDOW_STATE_SYNC_ID = guid();

export function generateUuidForAction(action) {
    const stampedAction = action;
    stampedAction.$uuid = guid();
    stampedAction.$wuid = WINDOW_STATE_SYNC_ID;
    return stampedAction;
}

export function isActionAllowed({ blacklist, whitelist }) {
    let allowed = () => true;
    if (Array.isArray(blacklist)) {
        allowed = (action) => blacklist.indexOf(action.type) < 0;
    } else if (Array.isArray(whitelist)) {
        allowed = (action) => whitelist.indexOf(action.type) >= 0;
    }
    return allowed;
}

export function MessageListener({ channel, dispatch, allowed }) {
    this.handleOnMessage = (stampedAction) => {
        if (stampedAction.$wuid === WINDOW_STATE_SYNC_ID) {
            return;
        }
        if (stampedAction.$uuid && stampedAction.$uuid !== lastUuid) {
            if (allowed(stampedAction)) {
                lastUuid = stampedAction.$uuid;
                dispatch(stampedAction);
            }
        }
    };
    if (channel) {
        this.messageChannel = channel;
        this.messageChannel.onmessage = channel ? this.handleOnMessage : null;
    }
}

export const createStateSyncMiddleware = (config) => {
    const allowed = isActionAllowed(config);

    let channel = null;
    let messageListener = null;

    return ({ dispatch }) =>
        (next) =>
        (action) => {
            const currentOrder = window.location.pathname.split('/')[2];
            const canCreate = isWorkbenchPage(currentOrder);

            // create channel
            if (!channel && canCreate) {
                channel = new BroadcastChannel(currentOrder, config.broadcastChannelOption);
            }
            // create message receiver
            if (!messageListener && canCreate) {
                messageListener = new MessageListener({ channel, dispatch, allowed });
            }
            if (!canCreate && channel && messageListener) {
                channel.close();
                channel = null;
                messageListener = null;
            }
            // post messages
            if (action && !action.$uuid && canCreate) {
                const stampedAction = generateUuidForAction(action);
                lastUuid = stampedAction.$uuid;
                try {
                    if (allowed(stampedAction)) {
                        channel.postMessage(stampedAction);
                    }
                } catch (e) {
                    console.error("Your browser doesn't support cross tab communication", e);
                }
            }
            // preserve client state in session storage
            if (
                Array.isArray(config.specialClientActions) &&
                config.specialClientActions?.length
            ) {
                config.specialClientActions.forEach((sliceName) => {
                    if (action.type.includes(sliceName)) {
                        window.sessionStorage.setItem(
                            action.type,
                            JSON.stringify(action.payload)
                        );
                    }
                });
            }
            return next(action);
        };
};
