import { handleError } from 'core/helpers/errorHandler';
import { ExaminerDocumentType } from 'types/dataModels';
import api from '../api';

/**
 * Get all document types
 * @function
 * @category API
 * @subcategory documentType
 * @returns {ExaminerDocumentType[]} An array of ExaminerDocumentType objects.
 */
export const getDocumentType = async (): Promise<ExaminerDocumentType[]> => {
    try {
        const response = await api.getWithCache<ExaminerDocumentType[]>(
            '/api/ExaminerDocumentType'
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
