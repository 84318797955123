import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Typography,
    TextField,
    Divider,
    styled,
    DialogActions,
    Button
} from '@mui/material';
import colors from 'core/constants/colors';
import { textStylesMontserrat } from 'styles/common';
import IconButtonMain from 'components/styled/IconButton';
import { Icon, IconPaths } from 'components/styled/Icon';
import { toast } from 'react-hot-toast';
import useAddNewDynamicConfigMutation from 'core/hooks/mutations/useAddNewDynamicConfigMutation';
import { ComponentData, NewRowEntry } from 'types/configManager'; //ForeignKeyWithMenuItems,
import { SelectItem } from 'types/propTypes';
import { createDropDownComponent } from 'core/helpers/determineInputHeaderComponent';
import { ButtonCaseProps } from 'types/propTypes';

// import InputMain from 'components/styled/Input';
// import { ButtonVariants } from 'core/constants/common';

/* eslint-disable @typescript-eslint/no-explicit-any */
const updateForm = (formData: NewRowEntry, values: { [k: string]: string }) => {
    let retForm = { ...formData };
    for (const key in values) {
        if (key in retForm) {
            retForm = {
                ...retForm,
                [key]: values[key]
            };
        }
    }

    const updatedKeys: { [k: string]: string } = {};
    for (const key in retForm.keys) {
        if (key in values) {
            updatedKeys[key] = values[key];
        }
    }

    retForm = {
        ...retForm,
        keys: updatedKeys
    };

    const updatedRes: { keyId: string; name: string; value: string }[] = [];
    formData.results.forEach((keyObj) => {
        if (keyObj.name in values) {
            updatedRes.push({
                keyId: keyObj.keyId,
                name: keyObj.name,
                value: values[keyObj.name]
            });
        }
    });
    retForm = {
        ...retForm,
        results: updatedRes
    };

    return retForm;
};

interface ConfigModalAddRowProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    columnData: ComponentData;
    categoryId: string;
    updateRowData: () => void;
}

interface ConfigInputProps {
    width?: number;
    height?: number;
    padding?: number;
}

const PrimaryButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'retainCase'
})<ButtonCaseProps>(({ retainCase }) => ({
    backgroundColor: '#483D91',
    fontWeight: 600,
    color: 'white',
    fontSize: '14px',
    lineHeight: '16px',
    height: '32px',
    minWidth: 'max-content',
    padding: '8px 12px',
    border: '1.5px solid #483D91',
    borderRadius: 0,
    textTransform: retainCase ? 'none' : 'uppercase',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    '&.Mui-focusVisible': {
        outline: '2px solid #643BC4',
        outlineOffset: '-2px',
        backgroundColor: colors.main.tertiaryDark
    },
    '&:hover': {
        backgroundColor: colors.main.tertiaryDark
    }
}));

export const StyledInput = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'width'
})<Partial<ConfigInputProps>>(({ width = '100%', height = '32px', padding = '8px' }) => ({
    '& .MuiInputBase-input': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: colors.main.primaryLight,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: '0',
        height: height,
        width: width,
        padding: padding,
        caretColor: colors.main.accentHighlight,
        '& fieldset': {
            borderColor: colors.main.secondaryLight
        },
        '&.Mui-focused fieldset': {
            borderColor: colors.main.accentHighlight
        },
        '&.Mui-error fieldset': {
            borderColor: colors.additional.error_fail
        }
    },
    '& .MuiOutlinedInput-input': {
        padding: '8px'
    },
    '& .MuiFormHelperText-root': {
        marginLeft: 0,
        marginTop: '2px'
    },
    '&.MuiFormControl-root': {
        width: '100%'
    }
}));

const SecondaryButton = styled(PrimaryButton)({
    backgroundColor: colors.main.primaryLight,
    border: '1.5px solid #483D91',
    color: '#483D91',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    height: '32px',
    minWidth: 'max-content',
    padding: '8px 12px',
    borderRadius: 0,
    '&:hover': {
        backgroundColor: colors.main.accentHighlight15
    },
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    '&.Mui-focusVisible': {
        outline: '2px solid #643BC4',
        outlineOffset: '-2px',
        backgroundColor: colors.main.accentHighlight15
    }
});

const InputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});

const ConfigAddRowModal = ({
    isOpen,
    columnData,
    categoryId,
    setIsOpen,
    updateRowData
}: ConfigModalAddRowProps) => {
    const { subtitle_16_semibold, subtitle_12_bold } = textStylesMontserrat().classes;
    const { addNewDynamicConfig } = useAddNewDynamicConfigMutation();
    const [newRowForm, setNewRowForm] = useState(null);

    const { configCategory, fieldDefs, foreignKeys } = columnData;

    useEffect(() => {
        const results: { keyId: string; name: string; value: string }[] = [];
        const newKeys: { [key: string]: string } = {};
        foreignKeys.forEach((key) => {
            newKeys[key.name] = '';
        });
        fieldDefs.forEach((field) =>
            results.push({ keyId: field.id, name: field.fieldName, value: '' })
        );
        const formSkeleton: NewRowEntry = {
            modelId: categoryId,
            name: '',
            keys: newKeys,
            results: results
        };
        setNewRowForm(formSkeleton);
    }, [columnData]);

    const handleClose = () => {
        setIsOpen(false);
    };

    /**
     * We will run through the generated form and input the values.
     * We can use this for validation in the future if we need.
     * @param form
     */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const handleSubmit = (form: { [k: string]: any }) => {
        const newRow = updateForm(newRowForm, form);
        addNewDynamicConfig.mutate(newRow, {
            onSuccess: () => {
                updateRowData();
                toast.success(`Added config!`, {
                    duration: 1000,
                    position: 'top-center'
                });
            },
            onSettled: () => {
                handleClose();
            }
        });
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth={'lg'}
            disablePortal={true}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    /* eslint-disable @typescript-eslint/no-explicit-any */
                    const formJson = Object.fromEntries((formData as any).entries());
                    handleSubmit(formJson);
                }
            }}>
            <DialogTitle
                sx={{
                    padding: '0px'
                }}>
                <Grid
                    id="add-config-row-header"
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{
                        padding: '13px 15px 15px 11px',
                        backgroundColor: colors.neutral.neutralLight,
                        width: '100%'
                    }}>
                    <Typography
                        className={subtitle_16_semibold}
                        color={colors.main.secondaryDark}>
                        {` Add new ${configCategory}`}
                    </Typography>
                    <IconButtonMain
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        withoutBackground
                        icon={<Icon fill={colors.main.primaryDark} path={IconPaths.close} />}
                    />
                </Grid>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    padding: '16px 8px 16px 16px'
                }}>
                <Grid container display={'flex'} flexDirection={'column'}>
                    <Grid item xs={4}>
                        <InputLabel>Name</InputLabel>
                        <StyledInput margin="dense" id="name" name="name" />
                    </Grid>
                    {fieldDefs.length > 0 && (
                        <>
                            <Grid
                                item
                                sx={{
                                    padding: '10px 0 10px 0'
                                }}>
                                <Divider orientation="horizontal" />
                            </Grid>
                            <Grid item>
                                <Typography
                                    className={subtitle_12_bold}
                                    color={colors.main.secondaryDark}>
                                    Field Definitions
                                </Typography>
                            </Grid>
                            <Grid container display={'flex'} direction={'column'}>
                                {fieldDefs.map((fieldDef, index) => {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            display={'flex'}
                                            sx={{
                                                padding: '10px 10px 10px 0',
                                                width: '34%',
                                                flexDirection: 'column'
                                            }}>
                                            <InputLabel sx={{ marginBottom: '5px' }}>
                                                {fieldDef.fieldName}
                                            </InputLabel>
                                            <StyledInput
                                                required
                                                name={fieldDef.fieldName}
                                                id={fieldDef.fieldName}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                    {foreignKeys.length > 0 && (
                        <>
                            <Grid
                                item
                                sx={{
                                    padding: '10px 0 10px 0'
                                }}>
                                <Divider orientation="horizontal" />
                            </Grid>
                            <Grid item>
                                <Typography
                                    className={subtitle_12_bold}
                                    color={colors.main.secondaryDark}>
                                    Foreign Keys
                                </Typography>
                            </Grid>
                            <Grid container display={'flex'} direction={'column'}>
                                {foreignKeys.map((fk, index) => {
                                    const selArr: SelectItem[] =
                                        fk.dropdownMenuItems.items.map((dd) => {
                                            return {
                                                id: dd.id,
                                                value: dd.name
                                            };
                                        });
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={2}
                                            display={'flex'}
                                            sx={{
                                                padding: '10px 10px 10px 0',
                                                width: '34%'
                                            }}>
                                            {createDropDownComponent(
                                                'newRow',
                                                selArr,
                                                fk.name,
                                                fk.id
                                            )}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '0px'
                }}>
                <Grid
                    display={'flex'}
                    justifyContent={'space-between'}
                    sx={{
                        width: '100%',
                        backgroundColor: colors.neutral.neutralLight,
                        padding: '15px 25px'
                    }}>
                    <Grid
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'20px'}>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </Grid>
                    <SecondaryButton retainCase onClick={handleClose}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ConfigAddRowModal;
