import { Grid, SelectChangeEvent } from '@mui/material';
import MainButton from 'components/styled/MainButton';
import MainSelect from 'components/styled/Select';
import colors from 'core/constants/colors';
import { ButtonVariants } from 'core/constants/common';
import useCategoryModelListQuery from 'core/hooks/query/ConfigManager/useCategoryModelListQuery';

interface ConfigHeaderProps {
    categoryId: string;
    setCategoryId: (value: string) => void;
    isFilterOn: boolean;
    setIsFilterOn: (value: boolean) => void;
    setIsRowModalOpen: (value: boolean) => void;
}

const ConfigHeader = ({
    categoryId,
    setCategoryId,
    isFilterOn,
    setIsFilterOn,
    setIsRowModalOpen
}: ConfigHeaderProps) => {
    const { categoryDropdownQuery, selectItems } = useCategoryModelListQuery();

    // console.log(selectItems);
    const {
        isLoading: dropdownDataLoading,
        error: dropdownError,
        isError: isDropdownError
    } = categoryDropdownQuery;

    const handleCategoryChange = (event: SelectChangeEvent) => {
        setCategoryId(event.target.value);
        setIsFilterOn(false);
    };

    if (dropdownDataLoading) {
        return <Grid>Loading dropdown...</Grid>;
    }

    if (isDropdownError) {
        return <Grid> Error getting categories for cat list: {dropdownError as string}</Grid>;
    }

    return (
        <Grid
            container
            flexDirection={'row'}
            sx={{
                padding: '10px 15px 0 0',
                zIndex: 1,
                backgroundColor: colors.main.primaryLight
            }}>
            <Grid item md={2}>
                <MainSelect
                    label={'Category'}
                    selectOnChange={handleCategoryChange}
                    selectValue={categoryId}
                    menuItems={selectItems}
                />
            </Grid>
            {categoryId && (
                <>
                    <Grid item sx={{ margin: '20px 0 0 20px' }}>
                        <MainButton
                            variant={ButtonVariants.primary}
                            retainCase
                            text={'Filter'}
                            action={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setIsFilterOn(!isFilterOn);
                            }}
                            disabled={false}
                        />
                    </Grid>
                    <Grid item sx={{ margin: '20px 0 0 20px' }}>
                        <MainButton
                            variant={ButtonVariants.primary}
                            retainCase
                            text={`Add New ${
                                selectItems?.find((item) => item.id === categoryId).display
                            }`}
                            action={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setIsRowModalOpen(true);
                            }}
                            disabled={false}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default ConfigHeader;
