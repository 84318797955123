import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentGroup, WorkbenchTabsEnum } from 'core/constants/common';
import { IDoc } from 'core/mocks/docViewBox';

interface DocumentViewerState {
    currentPanel: WorkbenchTabsEnum | DocumentGroup | null;
    currentDoc: IDoc;
    isOCRed: boolean;
    currentDocSelectValue: string;
    isModalOpen: boolean;
    currentOCRElement: {
        name: string;
        value: string;
    };
    hint: string;
    currentHeight: number;
    currentWidth: number;
    currentZoom: number;
    currentDocFileIndex: number;
}

const initialState: DocumentViewerState = {
    currentPanel: null,
    currentDoc: null,
    isOCRed: false,
    currentDocSelectValue: '',
    isModalOpen: false,
    currentOCRElement: null,
    hint: '',
    currentHeight: 800,
    currentWidth: 1200,
    currentZoom: 1,
    currentDocFileIndex: 0
};

const documentViewerSlice = createSlice({
    name: 'documentViewer',
    initialState,
    reducers: {
        /**
         * Set the panel that launched the document viewer
         * @param state Slice state
         * @param action Payload with the name of the Workbench panel that launched the document viewer
         */
        setCurrentDocumentViewerPanel(
            state,
            action: PayloadAction<WorkbenchTabsEnum | DocumentGroup, null>
        ) {
            state.currentPanel = action.payload;
        },
        /**
         * Set the current document in the viewer
         * @param state Slice state
         * @param action Payload with the object containing the document data
         */
        setCurrentDocument(state, action: PayloadAction<IDoc>) {
            state.currentDoc = action.payload;
        },
        /**
         * Set the isOCRed flag in the current document
         * @param state Slice state
         * @param action Payload with the isOCRed value to set
         */
        setIsOCRedDocument(state, action: PayloadAction<boolean>) {
            state.isOCRed = action.payload;
        },
        /**
         * Set the value of the documents select menu in the document viewer
         * @param state Slice state
         * @param action Payload with the ID of the selected document
         */
        setCurrentDocSelectValue(state, action: PayloadAction<string>) {
            state.currentDocSelectValue = action.payload;
        },
        /**
         * Set the index of the current document in the files array
         * @param state Slice state
         * @param action Payload index value to set
         */
        setCurrentDocFileIndex(state, action: PayloadAction<number>) {
            state.currentDocFileIndex = action.payload;
        },
        /**
         * Set the open state of the document viewer modal
         * @param state Slice state
         * @param action Payload with the isModalOpen value to set
         */
        setModalState(state, action: PayloadAction<boolean>) {
            state.isModalOpen = action.payload;
        },
        /**
         * Set the current OCR element in the OCR viewer
         * @param state Slice state
         * @param action Payload with the data of the OCR element to set
         */
        setCurrentOCRElement(state, action: PayloadAction<{ name: string; value: string }>) {
            state.currentOCRElement = action.payload;
        },
        /**
         * Set the hint
         * @param state Slice state
         * @param action Payload with the hint to set
         */
        setHint(state, action: PayloadAction<string>) {
            state.hint = action.payload;
        },
        /**
         * Set the current size of the document viewer modal
         * @param state Slice state
         * @param action Payload with the width and height values of the document viewer modal
         */
        setCurrentSize(state, action: PayloadAction<{ width: number; height: number }>) {
            state.currentHeight = action.payload.height;
            state.currentWidth = action.payload.width;
        },
        /**
         * Set the level of zoom in the document viewer
         * @param state Slice state
         * @param action Payload with the zoom value to set
         */
        setCurrentZoomAction(state, action: PayloadAction<number>) {
            state.currentZoom = action.payload;
        }
    }
});

export const {
    setCurrentDocumentViewerPanel,
    setCurrentDocument,
    setIsOCRedDocument,
    setCurrentDocSelectValue,
    setModalState,
    setCurrentOCRElement,
    setHint,
    setCurrentSize,
    setCurrentZoomAction,
    setCurrentDocFileIndex
} = documentViewerSlice.actions;

export default documentViewerSlice.reducer;
