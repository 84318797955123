import { handleError } from 'core/helpers/errorHandler';
import { NewTaxDocument, Tax } from 'types/taxes';
import { CreatePulseDocumentFileDto } from 'types/dataModels';
import { UploadTaxFormData } from 'types/uploadTaxForm';
import api from '../api';

/**
 * Uploads a new tax document
 * @function
 * @category API
 * @param {string} orderId
 * @param {UploadTaxFormData} formData
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @returns {Tax}
 */
export const uploadTaxWithNoImage = async (
    orderId: string,
    formData: UploadTaxFormData,
    uploadedFiles: CreatePulseDocumentFileDto[]
): Promise<Tax> => {
    try {
        const codeTemplateIds: number[] = formData.codes.reduce(
            (acc, current) => [...acc, current.id],
            []
        );
        const newTaxDocument: NewTaxDocument = {
            ...formData,
            propertyId: formData.property.id,
            documentType: {
                ...formData.documentType,
                documentCategoryId: formData.category.id,
                documentSubCategoryId: formData.subCategory.id
            },
            codeTemplateIds,
            files: uploadedFiles
        };
        const response = await api.post<NewTaxDocument, Tax>(
            `/api/examOrder/${orderId}/document/tax/withNoImage`,
            newTaxDocument
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
