import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DocumentSource,
    Installment,
    UploadTaxFormData,
    UploadTaxFormErrors
} from 'types/uploadTaxForm';
import { DocumentType, DocumentNote, PulseDocumentPartyField } from 'types/dataModels';
import { AppThunk } from 'core/store/store';

interface UploadTaxFormState {
    isOpen: boolean;
    formData: UploadTaxFormData;
    fieldErrors: UploadTaxFormErrors;
    cansubmitForm: boolean;
    isDirty: boolean;
}

export const initialState: UploadTaxFormState = {
    isOpen: false,
    formData: {
        category: { id: '', name: '' },
        subCategory: { id: '', name: '' },
        codes: [],
        documentType: {} as DocumentType,
        property: { id: '', value: '' },
        documentSource: {} as DocumentSource,
        installments: [] as Installment[],
        baseAmount: null,
        amountDue: null,
        amountPaid: null,
        foundDelinquent: null,
        dueDate: null,
        datePaid: null,
        delinquentDate: null,
        payDate: null,
        totalAssessment: null,
        appraisedValueLand: null,
        appraisedValueImprovement: null,
        appraisedValueTotal: null,
        assessedValueLand: null,
        assessedValueImprovement: null,
        assessedValueTotal: null,
        isHomestead: false,
        hasExemptions: false,
        exemptionAdditionalAmount: null,
        exemptionAmount: null,
        exemptionHomesteadSupplierAmount: null,
        exemptionMortgageAmount: null,
        exemptionOtherAmount: null,
        taxVolume: null,
        saleDate: null,
        notes: [] as DocumentNote[],
        codeTemplateIds: null,
        instrumentNumber: null,
        instrumentYear: null,
        bookType: '',
        liber: '',
        page: '',
        documentNumber: null,
        recordedDate: null,
        datedDate: null,
        effectiveDate: null,
        parties: [] as PulseDocumentPartyField[],
        documentLegalDescription: null,
        city: null
    },
    fieldErrors: {
        documentType: false,
        categoryId: false,
        subCategoryId: false,
        delinquentDate: false,
        payDate: false
    },
    cansubmitForm: false,
    isDirty: false
};

const uploadTaxFormSlice = createSlice({
    name: 'uploadTaxForm',
    initialState,
    reducers: {
        /**
         * Set isOpen
         * @param state Slice state
         * @param action Payload open value
         */
        setIsOpen(state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
        /**
         * Set the fields of the new tax form
         * @param state Slice state
         * @param action Payload with the new tax form data
         */
        setFormData(state, action: PayloadAction<UploadTaxFormData>) {
            state.formData = action.payload;
        },
        /**
         * Set the errors of the new tax form
         * @param state Slice state
         * @param action Payload with the new tax form errors
         */
        setFieldErrors(state, action: PayloadAction<UploadTaxFormErrors>) {
            state.fieldErrors = action.payload;
        },
        /**
         * Set canSubmitForm
         * @param state Slice state
         * @param action Payload can submit boolean
         */
        setCanSubmitForm(state, action: PayloadAction<boolean>) {
            state.cansubmitForm = action.payload;
        },
        /**
         * Set isDirty
         * @param state Slice state
         * @param action Payload is dirty boolean
         */
        setIsDirty(state, action: PayloadAction<boolean>) {
            state.isDirty = action.payload;
        },
        /**
         * Update the parties of the new tax
         * @param state Slice state
         * @param action Payload with the list of parties to set
         */
        setParties(state, action: PayloadAction<PulseDocumentPartyField[]>) {
            state.formData.parties = action.payload;
        },
        /**
         * Update the installments of the new tax
         * @param state Slice state
         * @param action Payload with the list of installments to set
         */
        setInstallments(state, action: PayloadAction<Installment[]>) {
            state.formData.installments = action.payload;
        }
    }
});

export const {
    setIsOpen,
    setFormData,
    setFieldErrors,
    setCanSubmitForm,
    setIsDirty,
    setParties,
    setInstallments
} = uploadTaxFormSlice.actions;

/**
 * Create a new party field and chagne focus to it
 * @param {PulseDocumentPartyField[]} parties
 * @param {HTMLInputElement} ref
 * @returns {AppThunk}
 */
export const createUploadTaxFormParties =
    (parties: PulseDocumentPartyField[], ref?: HTMLInputElement): AppThunk =>
    async (dispatch) => {
        dispatch(setParties(parties));
        if (ref) setTimeout(() => ref.focus(), 100);
    };

/**
 * Create new installments field and chagne focus to it
 * @param {Installment[]} installments
 * @param {HTMLInputElement} ref
 * @returns {AppThunk}
 */
export const createUploadTaxFormInstallments =
    (installments: Installment[], ref?: HTMLInputElement): AppThunk =>
    async (dispatch) => {
        dispatch(setInstallments(installments));
        if (ref) setTimeout(() => ref.focus(), 1000);
    };

export default uploadTaxFormSlice.reducer;
