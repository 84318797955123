import { useAxiosLoader } from 'core/api/api';
import LoadingModal from 'components/common/LoadingModal';

/**
 * Wrapper for LoadingModal component
 * @component
 * @returns {JSX.Element}
 */
const LoadingSpinner = () => {
    const [loading] = useAxiosLoader();
    return <LoadingModal isOpen={loading} cancelHandler={null} />;
};

export default LoadingSpinner;
