import { QueryClient, QueryCache } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    },
    queryCache: new QueryCache({
        onError(error) {
            toast.error(`Something went wrong: ${error}`, {
                duration: 1000,
                position: 'top-right'
            });
        },
        onSuccess(data) {
            if (data) {
                toast.success('Success!', {
                    duration: 1000,
                    position: 'top-right'
                });
            }
        }
        // onSettled(data, error, query) {
        //     console.log('Weve been settled: ' + data)
        // }
    })
    // mutationCache: new MutationCache({
    //     onSuccess():
    // })
});

export default queryClient;
