import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DragDropState {
    isDragging: boolean;
}

const initialState: DragDropState = {
    isDragging: false
};

const dragDropSlice = createSlice({
    name: 'dragDrop',
    initialState,
    reducers: {
        setDragging(state, action: PayloadAction<DragDropState['isDragging']>) {
            state.isDragging = action.payload;
        }
    }
});

export const { setDragging } = dragDropSlice.actions;

export default dragDropSlice.reducer;
