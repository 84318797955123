import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { State } from 'types/dataModels';

interface StatesState {
    states: State[];
}

const initialState: StatesState = {
    states: []
};

const statesSlice = createSlice({
    name: 'states',
    initialState,
    reducers: {
        /**
         * Set states data to the store
         * @param state Slice state
         * @param action Payload with the list of states to set
         */
        setStates(state, action: PayloadAction<State[]>) {
            state.states = action.payload;
        }
    }
});

export const { setStates } = statesSlice.actions;

/**
 * Fetch states data from BE
 * @returns {AppThunk}
 */
export const fetchStates = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.state.getStates();
        dispatch(setStates(response));
    } catch (err) {
        dispatch(setStates([]));
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get states: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default statesSlice.reducer;
