import React, {
    forwardRef,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState
} from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    InputAdornment,
    FormHelperText
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/material/styles';
import { SelectItem } from 'types/propTypes';
import { InputLabelPositions } from 'core/constants/common';
import colors from 'core/constants/colors';

const DefaultSelect = styled(Select)({
    '&.MuiInput-root': {
        width: '100%',

        border: `1px solid ${colors.main.secondaryLight}`,
        padding: '8px',
        height: '32px',
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        minWidth: '50px',
        color: colors.main.primaryDark,
        '& .MuiSelect-select': {
            padding: 0
        },
        '&.Mui-focused': {
            borderWidth: '2px',
            borderColor: colors.main.accentHighlight,
            '& .MuiSelect-select': {
                backgroundColor: colors.main.primaryLight
            }
        },
        '&.Mui-error': {
            borderColor: colors.additional.error_fail
        },
        '& .MuiInputAdornment-root': {
            margin: '0px',
            position: 'absolute',
            right: '16px'
        }
    }
});

const ITEM_HEIGHT = 32;

const StyledPlaceholder = styled('span')({
    color: colors.neutral.neutralMid
});

const InputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});

export interface ISelectProps {
    selectOnChange: (event: SelectChangeEvent) => void;
    selectValue: string;
    menuItems: SelectItem[];
    placeholder?: string;
    label?: string;
    labelPosition?: InputLabelPositions;
    minWidth?: number;
    /**
     * prop for changing select component background color
     */
    backgroundColor?: string;
    disabled?: boolean;
    icon?: JSX.Element;
    /**
     * If true indicates an error in field
     */
    error?: boolean;
    /**
     * Show icon when error is true
     */
    withIcon?: boolean;
    /**
     * If true show helper text under input, used when error is true
     */
    helperText?: string;
    name?: string;
    id?: string;
}

/**
 * Select component
 * @param {Function} selectOnChange onChange handler
 * @param {string[]} selectValue Current select value
 * @param {SelectItem[]} menuItems Dropdown items
 * @param {string} placeholder Placeholder value
 * @param {InputLabelPositions} labelPosition Label position
 * @param {string} label Label value
 * @param {number} minWidth min width
 * @param {boolean} disabled Is disabled
 * @param {JSX.Element} icon Icon value
 * @param {boolean} error Is have errors
 * @param {boolean} withIcon Use with icon
 * @param {string} helperText Helper text in bottom
 * @param {string} backgroundColor Background color
 * @constructor
 */

const MainSelect = forwardRef(
    (
        {
            selectOnChange,
            selectValue,
            menuItems,
            placeholder,
            labelPosition,
            label,
            minWidth = 50,
            disabled = false,
            icon,
            error,
            withIcon,
            helperText,
            backgroundColor = colors.main.primaryLight,
            name,
            id
        }: ISelectProps,
        forwardedRef: React.Ref<HTMLSelectElement>
    ) => {
        const [dropDownWidth, setDropDownWidth] = useState(0);
        const ref = useRef(null);
        let resultIcon = icon;

        if (error && withIcon) {
            resultIcon = (
                <InputAdornment position="end">
                    <WarningIcon color={'error'} />
                </InputAdornment>
            );
        }

        useLayoutEffect(() => {
            setDropDownWidth(ref.current.node.offsetWidth);
        }, [ref.current]);

        // forward external ref to internal ref
        useImperativeHandle(forwardedRef, () => ref.current);

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection:
                        labelPosition === InputLabelPositions.left ? 'row' : 'column',
                    gap: '6px'
                }}>
                {label && (
                    <InputLabel
                        sx={{
                            width: 'max-content'
                        }}>
                        {label}
                    </InputLabel>
                )}
                <FormControl
                    variant="standard"
                    error={error}
                    sx={{ m: 1, minWidth: minWidth, margin: 0, width: '100%' }}>
                    <DefaultSelect
                        name={name}
                        id={id}
                        inputRef={ref}
                        value={menuItems?.length > 0 ? selectValue : ''}
                        onChange={selectOnChange}
                        disabled={disabled}
                        disableUnderline
                        displayEmpty
                        sx={{
                            '&.MuiInput-root': {
                                backgroundColor: backgroundColor
                            }
                        }}
                        error={error}
                        endAdornment={resultIcon}
                        onOpen={() => {
                            setDropDownWidth(ref.current.node.offsetWidth);
                        }}
                        MenuProps={{
                            disableScrollLock: true,
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 10,
                                    minWidth: dropDownWidth,
                                    borderRadius: 0,
                                    border: `1px solid ${colors.main.secondaryLight}`,
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)'
                                }
                            }
                        }}>
                        {placeholder && (
                            <MenuItem value="">
                                <StyledPlaceholder>{placeholder}</StyledPlaceholder>
                            </MenuItem>
                        )}
                        {!!menuItems.length &&
                            menuItems.map((element) => {
                                return (
                                    <MenuItem
                                        sx={{
                                            padding: '10px 34px 10px 12px',
                                            fontFamily: 'Roboto, sans-serif',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            lineHeight: '16px'
                                        }}
                                        key={`${element.id}_${element.value}`}
                                        value={element.value}
                                        style={{
                                            display: element.isHidden ? 'none' : 'block'
                                        }}>
                                        {element.display ?? element.value}
                                    </MenuItem>
                                );
                            })}
                    </DefaultSelect>
                    {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            </Box>
        );
    }
);

MainSelect.displayName = 'MainSelect';

export default MainSelect;
