import { CircularProgress, Grid, Modal, Typography } from '@mui/material';
import colors from 'core/constants/colors';
import MainButton from 'components/styled/MainButton';
import { ButtonVariants } from 'core/constants/common';
import { textStylesRoboto } from 'styles/common';

interface LoadingModalProps {
    cancelHandler: () => void;
    isOpen: boolean;
}

/**
 * Loading modal component
 * @param {Function} cancelHandler
 * @param {boolean} isOpen
 * @component
 * @returns {JSX.Element}
 */
const LoadingModal = ({ cancelHandler, isOpen }: LoadingModalProps) => {
    const { body_14_regular, body_large_16_semibold } = textStylesRoboto().classes;
    return (
        <Modal open={isOpen} disableAutoFocus>
            <Grid
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '40%',
                    width: '20%',
                    padding: '25px',
                    background: colors.main.primaryLight
                }}>
                <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                    <Typography
                        className={body_large_16_semibold}
                        color={colors.main.secondaryDark}>
                        Please wait
                    </Typography>
                </Grid>
                <Grid
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    sx={{ paddingTop: '10px' }}>
                    <Typography textAlign={'center'} className={body_14_regular}>
                        Loading process
                    </Typography>
                </Grid>
                <Grid paddingTop={'30px'}>
                    <CircularProgress color="secondary" />
                </Grid>
                <Grid
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    paddingTop={'30px'}>
                    <MainButton
                        variant={ButtonVariants.secondary}
                        text={'Cancel'}
                        action={cancelHandler}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default LoadingModal;
