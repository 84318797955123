import Header from 'components/common/AppHeader';
import InlineLoadingSpinner from 'components/common/InlineLoadingSpinner';
import LeftSidebar from 'components/common/LeftSidebar';
import LoadingSpinner from 'components/common/LoadingSpinner';
import {
    DASHBOARD,
    ORDERS,
    TEST_ORDER_FORM,
    TEST_ORDERS,
    WORKBENCH,
    WORKBENCH_FORCE_EDITABLE,
    CONFIG_MANAGER
} from 'core/constants/navigation';
import { fetchBusinessSegments } from 'core/features/businessSegments/businessSegmentsSlice';
import { fetchClient } from 'core/features/client/clientSlice';
import { fetchCounty } from 'core/features/county/countySlice';
import { fetchDocumentTypes } from 'core/features/documentTypes/documentTypesSlice';
import { fetchInterestEstateTypes } from 'core/features/interestEstateType/interestEstateTypeSlice';
import { fetchProductTypes } from 'core/features/productTypes/productTypesSlice';
import { fetchProfileData } from 'core/features/profile/profileSlice';
import { fetchProjectStatuses } from 'core/features/projectStatus/projectStatusSlice';
import { fetchStates } from 'core/features/states/statesSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import WorkbenchLayout from 'pages/Workbench/WorkbenchLayout';
import ConfigManager from 'pages/ConfigManager';
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { lazyRetry } from 'core/helpers/lazyRetry';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorDisplay from './ErrorDisplay';
import usePageTracking from './usePageTracking';

const Dashboard = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "Dashboard" */ 'pages/Dashboard'), 'Dashboard')
);

const Orders = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "Orders" */ 'pages/Orders'), 'Orders')
);
const TestOrderForm = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "TestOrderForm" */ 'pages/TestOrders/TestOrderForm'),
        'TestOrderForm'
    )
);
const Workbench = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "Workbench" */ 'pages/Workbench'), 'Workbench')
);
const PopoutLayout = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "PopoutLayout" */ 'pages/Workbench/PopoutLayout'),
        'PopoutLayout'
    )
);

function AppLayout() {
    usePageTracking();
    const dispatch = useAppDispatch();
    /**
     * initial data loading
     */
    useEffect(() => {
        dispatch(fetchClient());
        dispatch(fetchStates());
        dispatch(fetchCounty());
        dispatch(fetchProductTypes());
        dispatch(fetchProjectStatuses());
        dispatch(fetchDocumentTypes());
        dispatch(fetchBusinessSegments());
        dispatch(fetchProfileData());
        dispatch(fetchInterestEstateTypes());
    }, []);

    return (
        <div className="content">
            <Header />
            <LeftSidebar />
            <section className="mainContainer">
                <LoadingSpinner />
                <ErrorBoundary fallback={<ErrorDisplay />}>
                    <Outlet />
                </ErrorBoundary>
            </section>
        </div>
    );
}

/**
 * Authorized view wrapper for application
 * @component
 * @returns {JSX.Element}
 */
export const AuthorizedView = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AppLayout />}>
                    <Route
                        path={DASHBOARD}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <Dashboard />
                            </Suspense>
                        }
                    />
                    <Route
                        path={ORDERS}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <Orders />
                            </Suspense>
                        }
                    />
                    <Route
                        path={`${WORKBENCH}/:orderId`}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <WorkbenchLayout />
                            </Suspense>
                        }>
                        <Route index element={<Workbench />} />
                    </Route>
                    <Route
                        path={`${WORKBENCH_FORCE_EDITABLE}/:orderId`}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <WorkbenchLayout forceEditable={true} />
                            </Suspense>
                        }>
                        <Route index element={<Workbench />} />
                    </Route>
                    <Route
                        path={`${TEST_ORDERS}/${TEST_ORDER_FORM}`}
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <TestOrderForm />
                            </Suspense>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <Suspense fallback={<InlineLoadingSpinner />}>
                                <Navigate to={`${ORDERS}`} replace />
                            </Suspense>
                        }
                    />
                    <Route path={CONFIG_MANAGER} element={<ConfigManager />} />
                </Route>
                <Route
                    path={`/${WORKBENCH}/:orderId/documents`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <PopoutLayout />
                        </Suspense>
                    }
                />
                <Route
                    path={`/${WORKBENCH_FORCE_EDITABLE}/:orderId/documents`}
                    element={
                        <Suspense fallback={<InlineLoadingSpinner />}>
                            <PopoutLayout forceEditable={true} />
                        </Suspense>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};
