import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertVariant, SnackbarSeverity } from '../../constants/common';

interface SnackbarState {
    /**
     * If true, the component is shown
     */
    open: boolean;
    /**
     * The message to display
     */
    message: string;
    /**
     * Position
     */
    anchorOrigin?: { horizontal: 'center' | 'left' | 'right'; vertical: 'bottom' | 'top' };
    /**
     * The variant to use
     * @type {AlertVariant}
     */
    alertVariant?: AlertVariant;
    /**
     * Severity
     * @type {SnackbarSeverity}
     */
    severity: SnackbarSeverity;
    /**
     * The number of milliseconds to wait before automatically calling the onClose function.
     */

    autoHideDuration?: number;
}

const initialState: SnackbarState = {
    open: false,
    message: '',
    severity: SnackbarSeverity.Success,
    autoHideDuration: 6000,
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    alertVariant: AlertVariant.Filled
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        /**
         * Set the snackbar state to store
         * @param state Slice state
         * @param action Payload with the snackbar state to set
         */
        setSnackbarState(state, action: PayloadAction<SnackbarState>) {
            state.open = action.payload.open;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
        /**
         * Close snackbar and reset the message
         * @param state Slice state
         */
        setSnackbarClose(state) {
            state.open = false;
            state.message = '';
        }
    }
});

export const { setSnackbarState, setSnackbarClose } = snackbarSlice.actions;

export default snackbarSlice.reducer;
