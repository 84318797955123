import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PayloadTypes {
    id: string;
}

const initialState = {
    ownerForms: [] as string[]
};

const testOrderOwnerFormsSlice = createSlice({
    name: 'testOrderOwnerForms',
    initialState,
    reducers: {
        /**
         * Add a new owner form to test order
         * @param state Slice state
         * @param action Payload with the ID to add to owner forms
         */
        addOwnerForm(state, action: PayloadAction<PayloadTypes['id']>) {
            state.ownerForms.push(action.payload);
        },
        /**
         * Remove an owner form from test order state
         * @param state Slice state
         * @param action Payload with the ID to remove from owner forms
         */
        removeOwnerForm(state, action: PayloadAction<PayloadTypes['id']>) {
            const elementIndex = state.ownerForms.indexOf(action.payload);
            if (elementIndex >= 0) {
                state.ownerForms.splice(elementIndex, 1);
            }
        },
        /**
         * Remove all owner forms from test order
         * @param state Slice state
         */
        removeAllOwnerForms(state) {
            state.ownerForms = initialState.ownerForms;
        },
        /**
         * Set the owner forms in test order
         * @param state Slice state
         * @param action Payload with array of IDs to set
         */
        setOwnerForms(state, action: PayloadAction<typeof initialState.ownerForms>) {
            state.ownerForms = action.payload;
        }
    }
});

export const { addOwnerForm, removeOwnerForm, removeAllOwnerForms, setOwnerForms } =
    testOrderOwnerFormsSlice.actions;

export default testOrderOwnerFormsSlice.reducer;
