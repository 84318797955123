import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PayloadTypes {
    id: string;
}

const initialState = {
    parcelForms: ['0']
};

const testOrderParcelFormsSlice = createSlice({
    name: 'testOrderParcelForms',
    initialState,
    reducers: {
        /**
         * Add a new parcel number form to test order
         * @param state Slice state
         * @param action Payload with the ID to add to parcel number forms
         */
        addParcelNumberForm(state, action: PayloadAction<PayloadTypes['id']>) {
            state.parcelForms.push(action.payload);
        },
        /**
         * Remove a parcel number form from test order state
         * @param state Slice state
         * @param action Payload with the ID to remove from parcel number forms
         */
        removeParcelNumberForm(state, action: PayloadAction<PayloadTypes['id']>) {
            const elementIndex = state.parcelForms.indexOf(action.payload);
            if (elementIndex >= 0) {
                state.parcelForms.splice(elementIndex, 1);
            }
        },
        /**
         * Remove all parcel number forms from test order
         * @param state Slice state
         */
        removeAllParcelNumberForms(state) {
            state.parcelForms = initialState.parcelForms;
        },
        /**
         * Set the parcel number forms in test order
         * @param state Slice state
         * @param action Payload with array of IDs to set
         */
        setParcelNumberForms(state, action: PayloadAction<typeof initialState.parcelForms>) {
            state.parcelForms = action.payload;
        }
    }
});

export const {
    addParcelNumberForm,
    removeParcelNumberForm,
    removeAllParcelNumberForms,
    setParcelNumberForms
} = testOrderParcelFormsSlice.actions;

export default testOrderParcelFormsSlice.reducer;
