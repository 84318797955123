import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { InterestEstateType } from 'types/dataModels';

interface InterestEstateTypesState {
    interestEstateTypes: InterestEstateType[];
}

const initialState: InterestEstateTypesState = {
    interestEstateTypes: []
};

const interestEstateTypesSlice = createSlice({
    name: 'interestEstateTypes',
    initialState,
    reducers: {
        /**
         * Set interest estate types data to store
         * @param state Slice state
         * @param action Payload with the list of interest estate types to set
         */
        setInterestEstateTypes(state, action: PayloadAction<InterestEstateType[]>) {
            state.interestEstateTypes = action.payload;
        }
    }
});

export const { setInterestEstateTypes } = interestEstateTypesSlice.actions;

/**
 * Fetch interest estate types data from BE
 * @returns {AppThunk}
 */
export const fetchInterestEstateTypes = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.interestEstateTypes.getInterestEstateType();
        dispatch(setInterestEstateTypes(response));
    } catch (err) {
        dispatch(setInterestEstateTypes([]));
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get interest estate types: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default interestEstateTypesSlice.reducer;
