import { OCRMockData } from 'core/mocks/ocrData';
import { IOCRResultsData } from 'types/dataModels';

export const OCRApi = async (fileName: string) => {
    const result = OCRMockData.find((data) => data.pages[0] === fileName);
    const data: IOCRResultsData = {
        ocrData: result.ocr,
        itemsData: result.items,
        aliasesData: result.aliases,
        pagesData: result.pages
    };
    return data;
};
