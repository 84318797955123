import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import { AppDispatch } from '../../store/store';

const LEGAL_DESCRIPTION_UNDO = 'LEGAL_DESCRIPTION_UNDO';
const LEGAL_DESCRIPTION_REDO = 'LEGAL_DESCRIPTION_REDO';
const LEGAL_DESCRIPTION_CLEAR = 'LEGAL_DESCRIPTION_CLEAR';

interface LegalDescriptionEditorState {
    textEditorValue: string;
}

const initialState: LegalDescriptionEditorState = {
    textEditorValue: ''
};

const legalDescriptionEditorSlice = createSlice({
    name: 'legalDescriptionEditor',
    initialState,
    reducers: {
        /**
         * Set the current value of the legal description editor to store
         * @param state Slice state
         * @param action Payload with the editor value to set
         */
        setLegalDescriptionEditorValue(state, action: PayloadAction<string>) {
            state.textEditorValue = action.payload;
        }
    }
});

export const { setLegalDescriptionEditorValue } = legalDescriptionEditorSlice.actions;
//, setLegalInitialText
const undoableLegalDescriptionEditorReducer = undoable(legalDescriptionEditorSlice.reducer, {
    undoType: LEGAL_DESCRIPTION_UNDO,
    redoType: LEGAL_DESCRIPTION_REDO,
    clearHistoryType: LEGAL_DESCRIPTION_CLEAR
});

export const undoEditor = () => (dispatch: AppDispatch) => {
    dispatch({ type: LEGAL_DESCRIPTION_UNDO });
};

export const redoEditor = () => (dispatch: AppDispatch) => {
    dispatch({ type: LEGAL_DESCRIPTION_REDO });
};

export const clearEditor = () => (dispatch: AppDispatch) => {
    dispatch({ type: LEGAL_DESCRIPTION_CLEAR });
};

export default undoableLegalDescriptionEditorReducer;
