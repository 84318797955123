import { useEffect } from 'react';
import ConfigTable from 'pages/ConfigManager/ConfigTable';
import { useQueryClient } from '@tanstack/react-query';
import styles from './Config.module.scss';

// import { Grid, SelectChangeEvent, Box } from '@mui/material';
// import useCategoryDropdownQuery from 'core/hooks/query/ConfigManager/useCategoryDropdownQuery';
// import { getCatModel } from 'core/features/configManager/configManagerSlice';
// import { SnackbarState, setSnackbarState } from 'core/features/snackbar/snackbarSlice';
// import { AlertVariant, SnackbarSeverity } from 'core/constants/common';

/**
 * Transition to context rather than Prop drilling
 * @returns {JSX.Element} Configuration table
 *
 */
const ConfigManager = () => {
    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries(['category', null]);
    }, []);

    /**
     *  Figure out if you should be using react query or redux for this
     * 1. Create a prefetch file, export the queries?
     * Need to figure that pattern out
     */

    return (
        <div className={styles.pageContainer}>
            <ConfigTable />
        </div>
    );
};

export default ConfigManager;
