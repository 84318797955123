import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    searchWarnings: {
        isPinned: false
    }
};

const searchWarningsSlice = createSlice({
    name: 'searchWarnings',
    initialState,
    reducers: {
        /**
         * Set the pinned state of the warnings panel
         * @param state Slice state
         * @param action Payload with the isPinned flag value
         */
        setWarningsPanelPinnedState(state, action: PayloadAction<boolean>) {
            state.searchWarnings.isPinned = action.payload;
        }
    }
});

export const { setWarningsPanelPinnedState } = searchWarningsSlice.actions;
export default searchWarningsSlice.reducer;
