import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { ProjectStatus } from 'types/dataModels';

/**
 * Get all project statuses
 * @function
 * @category API
 * @returns {ProjectStatus[]}
 */
export const getProjectStatuses = async (): Promise<ProjectStatus[]> => {
    try {
        const response = await api.getWithCache<ProjectStatus[]>('/api/projectStatus');
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
