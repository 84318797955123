import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { ExceptionsRequirementsPanelTypes, SnackbarSeverity } from 'core/constants/common';
import { CodeTemplate } from 'types/codebook';

interface ExamCodeBookState {
    codeTemplates: CodeTemplate[];
    lookupInput: string;
    targetSection: ExceptionsRequirementsPanelTypes;
}

const initialState: ExamCodeBookState = {
    codeTemplates: [],
    lookupInput: '',
    targetSection: null
};

const examCodeBookSlice = createSlice({
    name: 'examCodeBook',
    initialState,
    reducers: {
        /**
         * Set code book templates to state
         * @param state Slice state
         * @param action Payload with the list of code templates to set
         */
        setExamCodeBookTemplatesData(
            state: ExamCodeBookState,
            action: PayloadAction<CodeTemplate[]>
        ) {
            state.codeTemplates = action.payload;
        },
        /**
         * Set the keyword input to filter displayed codes
         * @param state Slice state
         * @param action Payload with the keyword input to set as filter
         */
        setLookupInput(state: ExamCodeBookState, action: PayloadAction<string>) {
            state.lookupInput = action.payload;
        },
        /**
         * Set the panel that launched the codebook so only valid codes for this section are shown
         * @param state Slice state
         * @param action Payload with the enum value of the panel that launched the codebook
         */
        setTargetSection(
            state: ExamCodeBookState,
            action: PayloadAction<ExceptionsRequirementsPanelTypes>
        ) {
            state.targetSection = action.payload;
        }
    }
});

export const { setExamCodeBookTemplatesData, setLookupInput, setTargetSection } =
    examCodeBookSlice.actions;

/**
 * Fetch exam code book templates data from BE
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchExamCodeBookTemplatesData =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examCodeBook.getCodeTemplates(orderId);
            dispatch(setExamCodeBookTemplatesData(response));
        } catch (err) {
            dispatch(setExamCodeBookTemplatesData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get codebook templates: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default examCodeBookSlice.reducer;
