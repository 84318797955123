import api from 'core/api/api';
import {
    createDataForCommonDocumentUpload,
    createDataForRecordedDocumentUpload
} from 'core/helpers/createDataForRecordedDocumentUpload';
import { handleError } from 'core/helpers/errorHandler';
import { updateDataForRecordedExistingDocument } from 'core/helpers/updateDataForRecordedDocumentUpload';
import { UploadSearchPackageFormData } from 'types/uploadSearchPackageForm';
import {
    CommonDocument,
    CreatePulseDocumentFileDto,
    KeyDocument,
    NewCommonDocumentWithFilesDto,
    NewRecordedDocumentWithFilesDto,
    UpdateRecordedDocumentDto
} from 'types/dataModels';
import { UploadKeyDocumentFormData } from 'types/uploadKeyDocumentForm';
import { overrideExamDocumentFile } from 'core/api/examBlobDocumentFile';

/**
 * Creates new recorded document from user input form (second step of document creation after uploading images)
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {UploadKeyDocumentFormData} formData
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {string} propertyId - Document property id
 * @returns {CommonDocument}
 */
export const uploadRecordedDocumentWithNoImage = async (
    orderId: string,
    formData: UploadKeyDocumentFormData,
    uploadedFiles: CreatePulseDocumentFileDto[],
    propertyId: string
): Promise<CommonDocument> => {
    try {
        const newKeyDocument = createDataForRecordedDocumentUpload(
            uploadedFiles,
            formData,
            propertyId
        );
        const response = await api.post<NewRecordedDocumentWithFilesDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/recorded/withNoImage`,
            newKeyDocument
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const updateExistingRecordedDocument = async (
    documentData: UploadKeyDocumentFormData,
    files: File[],
    orderId: string,
    document: KeyDocument,
    propertyId: string,
    categoryId: string,
    subCategoryId: string
): Promise<CommonDocument> => {
    const { isFileUpdated, uploadRequestPayload } = updateDataForRecordedExistingDocument(
        categoryId,
        subCategoryId,
        documentData,
        files,
        propertyId,
        document
    );
    let response;
    if (isFileUpdated) {
        await overrideExamDocumentFile(
            orderId,
            document.id,
            document.files?.[0]?.id,
            files?.[files?.length - 1]
        );
    }

    try {
        response = await api.put<FormData | UpdateRecordedDocumentDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/${document.id}/recorded`,
            uploadRequestPayload
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Creates new common document from user input form (second step of document creation after uploading images)
 * @function
 * @category API
 * @param {UploadSearchPackageFormData} formData - Data from input form
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Document property id
 * @returns {CommonDocument}
 */
export const uploadCommonDocumentWithNoImage = async (
    formData: UploadSearchPackageFormData,
    uploadedFiles: CreatePulseDocumentFileDto[],
    orderId: string,
    propertyId: string
): Promise<CommonDocument> => {
    const resultFormData = createDataForCommonDocumentUpload(
        uploadedFiles,
        formData,
        propertyId
    );
    try {
        const response = await api.post<NewCommonDocumentWithFilesDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/common/withNoImage`,
            resultFormData
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
