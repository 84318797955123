import { Box, Grid } from '@mui/material';
import MainButton from 'components/styled/MainButton';
import { textStylesMontserrat } from 'styles/common';
import styles from './styles';

const ErrorDisplay = () => {
    const { root } = styles().classes;
    const { subtitle_16_semibold } = textStylesMontserrat().classes;

    return (
        <Grid className={root} display={'flex'} flexDirection={'column'} gap={'30px'}>
            <Box className={subtitle_16_semibold} color={'#000000'}>
                Something went wrong!
            </Box>
            <MainButton
                retainCase={true}
                text={'Try Again'}
                action={() => window.location.reload()}></MainButton>
        </Grid>
    );
};

export default ErrorDisplay;
