import { handleError } from 'core/helpers/errorHandler';
import { County } from 'types/dataModels';
import api from '../api';

/**
 * This function will return a list of counties from the API, or throw an error if it fails.
 * @function
 * @category API
 * @subcategory counties
 * @returns {County[]} An array of County objects.
 */
export const getCounty = async (): Promise<County[]> => {
    try {
        const response = await api.getWithCache<County[]>('/api/county');
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
