import api from 'core/api';
// import { Category } from 'types/configManager';
import { useQuery } from '@tanstack/react-query'; //, useQueryClient
import { SelectItem } from 'types/propTypes';
import { ICategoryModelsData } from 'types/configManager';

/**
 * Top of file will be the fnQueries, so import the API calls
 * @returns {Promise<Category[]>} Promise with data
 */
export const getCategoryModels = (): Promise<ICategoryModelsData> => {
    const response = api.configManager.getCategoryModelsApi();
    return response;
};

/**
 * Define the hook to be used
 */

const useCategoryModelListQuery = () => {
    const categoryDropdownQuery = useQuery({
        queryKey: ['CategoryModels'],
        queryFn: () => getCategoryModels()
    });

    const selectItems: SelectItem[] = categoryDropdownQuery?.data?.items?.map(
        (head: { name: string; id: string }) => ({
            display: head.name,
            value: head.id,
            id: head.id
        })
    );

    return { categoryDropdownQuery, selectItems };
};

export default useCategoryModelListQuery;
