export const getPlural = (label: string) => {
    let plural: string;
    switch (label.charAt(label.length - 1)) {
        case 'y':
            plural = label.slice(0, -1) + 'ies';
            break;
        case 's':
            plural = label;
            break;
        default:
            plural = label + 's';
    }
    return plural;
};
