import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentType } from 'types/dataModels';
import {
    UploadSearchPackageFormData,
    UploadSearchPackageFormErrors
} from 'types/uploadSearchPackageForm';

interface UploadSearchPackageFormState {
    isOpen: boolean;
    formData: UploadSearchPackageFormData;
    fieldErrors: UploadSearchPackageFormErrors;
    cansubmitForm: boolean;
    isDirty: boolean;
}

export const initialState: UploadSearchPackageFormState = {
    isOpen: false,
    formData: {
        category: { id: '', name: '' },
        subCategory: { id: '', name: '' },
        searchCriteria: '',
        instrumentNumber: '',
        recordedDate: '',
        documentType: {} as DocumentType
    },
    fieldErrors: {
        documentType: false,
        categoryId: false,
        subCategoryId: false
    },
    cansubmitForm: false,
    isDirty: false
};

const uploadSearchPackageFormSlice = createSlice({
    name: 'uploadSearchPackageForm',
    initialState,
    reducers: {
        /**
         * Set isOpen
         * @param state Slice state
         * @param action Payload open value
         */
        setIsOpen(state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
        /**
         * Set the fields of the new search package form
         * @param state Slice state
         * @param action Payload with form data for new search package
         */
        setFormData(state, action: PayloadAction<UploadSearchPackageFormData>) {
            state.formData = action.payload;
        },
        /**
         * Set the errors of the new search package form
         * @param state Slice state
         * @param action Payload with the new search package form errors
         */
        setFieldErrors(state, action: PayloadAction<UploadSearchPackageFormErrors>) {
            state.fieldErrors = action.payload;
        },
        /**
         * Set canSubmitForm
         * @param state Slice state
         * @param action Payload can submit boolean
         */
        setCanSubmitForm(state, action: PayloadAction<boolean>) {
            state.cansubmitForm = action.payload;
        },
        /**
         * Set isDirty
         * @param state Slice state
         * @param action Payload is dirty boolean
         */
        setIsDirty(state, action: PayloadAction<boolean>) {
            state.isDirty = action.payload;
        }
    }
});

export const { setIsOpen, setFormData, setFieldErrors, setCanSubmitForm, setIsDirty } =
    uploadSearchPackageFormSlice.actions;

export default uploadSearchPackageFormSlice.reducer;
