import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { ProductType } from 'types/dataModels';

/**
 * Get the list of product types
 * @function
 * @category API
 * @returns {ProductType[]}
 */
export const getProductTypes = async (): Promise<ProductType[]> => {
    try {
        const response = await api.getWithCache<ProductType[]>('/api/productType');
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
