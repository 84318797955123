interface IHeaderData {
    id: number;
    headerName: HeaderNames;
    withSorting: boolean;
}

export enum HeaderNames {
    DS = 'DS',
    Date = 'Date',
    Description = 'Description',
    GrantorName = 'Grantor Name',
    GranteeName = 'Grantee Name',
    Instrument = 'Instrument',
    Category = 'Category',
    Subcategory = 'Subcategory',
    Actions = 'Actions'
}

export const headerData: IHeaderData[] = [
    { id: 0, headerName: HeaderNames.Date, withSorting: true },
    { id: 1, headerName: HeaderNames.Description, withSorting: true },
    { id: 2, headerName: HeaderNames.GrantorName, withSorting: false },
    { id: 3, headerName: HeaderNames.GranteeName, withSorting: false },
    { id: 4, headerName: HeaderNames.Instrument, withSorting: false },
    { id: 5, headerName: HeaderNames.Category, withSorting: false },
    { id: 6, headerName: HeaderNames.Subcategory, withSorting: false }
];
