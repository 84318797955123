import { useEffect, useState, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, GridSortModel, GridEventListener } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import useGridColumnQuery from 'core/hooks/query/ConfigManager/useGridColumnQuery';
import useGridRowQuery from 'core/hooks/query/ConfigManager/useGridRowQuery';
import useSingleCellUpdateMutation from 'core/hooks/mutations/useSingleCellUpdateMutation';
import useDeleteDynamicConfigMutation from 'core/hooks/mutations/useDeleteDynamicConfigMutation';
import colors from 'core/constants/colors';
import {
    UpdateFilterObj,
    ColumnType,
    RowEntry,
    DynamicConfigFilterDto,
    Filter,
    Sort
} from 'types/configManager';
import ConfigAddRowModal from 'components/common/ConfigAddRowModal/';
import { toast } from 'react-hot-toast';
import { uniqBy } from 'lodash';

const initialConfigFilterData: DynamicConfigFilterDto = {
    modelId: '',
    configName: '',
    resultFilters: [],
    foreignKeyFilters: [],
    fieldSorting: null,
    page: 0,
    pageSize: 20
};
interface ConfigManagerDataGridProps {
    categoryId: string;
    isFilterOn: boolean;
    isAddRowModalOpen: boolean;
    setIsRowModalOpen: (value: boolean) => void;
}

const StyledLinearProgress = styled(LinearProgress)(() => ({
    '& .MuiLinearProgress-bar2Indeterminate': {
        backgroundColor: colors.main.accent,
        borderRadius: '10px'
    },
    // Styles for the moving bar// does not change it
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: colors.main.accent,
        borderRadius: '10px'
    }
}));

const ConfigManagerDataTable = ({
    categoryId,
    isFilterOn,
    isAddRowModalOpen,
    setIsRowModalOpen
}: ConfigManagerDataGridProps) => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [configFilterData, setConfigFilterData] = useState(initialConfigFilterData);
    const filterDataRef = useRef<DynamicConfigFilterDto>(initialConfigFilterData);

    const specialRowData = useRef<RowEntry[]>(null);

    /**
     * This function is used to keep the state of the ref and the state obj consistent.
     * @param filterObj
     */
    const updateFilterState = (filterObj: DynamicConfigFilterDto) => {
        setConfigFilterData(filterObj);
        filterDataRef.current = filterObj;
    };

    /**
     * Update the filter object
     * @param filterOb
     * TODO: could probably make this a hook
     */
    const updateFilter = (filterOb: UpdateFilterObj) => {
        // We don't filter on these
        if (filterOb.colType === ColumnType.Default) return;
        const newFilter = filterOb.filters[0];
        const currentResultFilters = filterDataRef?.current.resultFilters;
        const currentKeysFilters = filterDataRef?.current.foreignKeyFilters;

        if (filterOb.filters)
            switch (filterOb.colType) {
                // We can only get one filter in the filters array for these
                // So we need to check if it exists yet
                case ColumnType.FieldDefinition: {
                    let newFilterObj: DynamicConfigFilterDto;
                    const oldFilter = currentResultFilters.find(
                        (filt) => filt.id === newFilter?.id
                    );
                    if (newFilter.value === '') {
                        if (currentResultFilters.length === 0) {
                            return;
                        } else {
                            newFilterObj = {
                                ...filterDataRef.current,
                                resultFilters: currentResultFilters.filter(
                                    (filt) => filt.id !== newFilter.id
                                )
                            };
                        }
                    } else {
                        if (oldFilter) {
                            newFilterObj = {
                                ...filterDataRef.current,
                                resultFilters: currentResultFilters.map((filt) => {
                                    return filt.id === newFilter.id
                                        ? {
                                              ...filt,
                                              value: newFilter.value
                                          }
                                        : filt;
                                })
                            };
                        } else {
                            if (currentResultFilters.length > 0) {
                                newFilterObj = {
                                    ...filterDataRef.current,
                                    resultFilters: [
                                        ...currentResultFilters,
                                        filterOb.filters[0]
                                    ]
                                };
                            } else {
                                newFilterObj = {
                                    ...filterDataRef.current,
                                    resultFilters: filterOb.filters
                                };
                            }
                        }
                    }
                    updateFilterState(newFilterObj);
                    break;
                }
                case ColumnType.ForeignKey: {
                    let newFilterObj: DynamicConfigFilterDto;
                    let keyFiltersArray: Filter[] = [];
                    // we need to see if there are other foriegn keys in the filter
                    const otherKeys = currentKeysFilters.findIndex(
                        (it) => it.id !== newFilter.id
                    );
                    if (otherKeys === -1) {
                        // We did not find any other foreign keys, append
                        if (newFilter.value === 'None' || newFilter.value === 'All') {
                            newFilterObj = {
                                ...filterDataRef.current,
                                foreignKeyFilters: currentKeysFilters.filter(
                                    (it) => it.id !== newFilter.id
                                )
                            };
                        } else {
                            newFilterObj = {
                                ...filterDataRef.current,
                                foreignKeyFilters: currentKeysFilters.concat(filterOb.filters)
                            };
                        }
                    } else {
                        if (newFilter.value === 'None' || newFilter.value === 'All') {
                            newFilterObj = {
                                ...filterDataRef.current,
                                foreignKeyFilters: currentKeysFilters.filter(
                                    (it) => it.id !== newFilter.id
                                )
                            };
                        } else {
                            keyFiltersArray = uniqBy(
                                currentKeysFilters.concat(filterOb.filters),
                                'value'
                            );
                            // We found the same key, so we are thinking this is the same key
                            newFilterObj = {
                                ...filterDataRef.current,
                                foreignKeyFilters: keyFiltersArray
                            };
                        }
                    }
                    updateFilterState(newFilterObj);
                    break;
                }
                case ColumnType.Name:
                    // setNameFilter(tempFilt);
                    break;
                default:
                    break;
            }
    };

    const deleteDynamicConfig = (configId: string) => {
        deleteConfigMutation.mutate(
            { configId },
            {
                onSuccess: () => {
                    // queryClient.setQueryData() // Set the query data of the filter with the row removed.
                    refetchRowData();
                    toast.success(`Dynamic Config has been deleted`);
                },
                onError: () => {
                    toast.error('Could not delete dynamic config', {
                        duration: 1000,
                        position: 'top-center'
                    });
                }
            }
        );
    };

    const { gridColumnQuery } = useGridColumnQuery(
        categoryId,
        updateFilter,
        deleteDynamicConfig
    );

    const { gridRowQuery } = useGridRowQuery(configFilterData);

    const { updateCellMutation } = useSingleCellUpdateMutation(categoryId);
    const { deleteConfigMutation } = useDeleteDynamicConfigMutation(categoryId);

    const {
        data: rowData,
        isLoading: rowDataIsLoading,
        isError: rowDataHasError,
        refetch: refetchRowData
    } = gridRowQuery;

    const {
        data: columnData,
        isError: columnDataHasError,
        status: columnStatus
    } = gridColumnQuery;

    const [key, setKey] = useState(0);

    const resetFilters = () => {
        updateFilterState({
            ...filterDataRef.current,
            resultFilters: [],
            foreignKeyFilters: [],
            fieldSorting: null
        });
    };

    const [columns, setColumns] = useState<GridColDef[]>([]);

    const [rowCountState, setRowCountState] = useState(rowData?.totalCount || 20);

    /**
     * Update the category ID on the filter model when we are changing Categories
     */
    useEffect(() => {
        updateFilterState({
            ...filterDataRef.current,
            modelId: categoryId
        });
    }, [categoryId]);

    /**
     * Update the filter when the page changes
     */
    useEffect(() => {
        updateFilterState({
            ...filterDataRef.current,
            page: paginationModel.page,
            pageSize: paginationModel.pageSize
        });
    }, [paginationModel]);

    useEffect(() => {
        if (sortModel.length === 0) return;
        const tempSortOb: Sort = {
            name: sortModel[0].field,
            direction: sortModel[0].sort === 'asc' ? 0 : 1
        };
        const tempFilterOb: DynamicConfigFilterDto = {
            ...filterDataRef.current,
            fieldSorting: tempSortOb
        };
        updateFilterState(tempFilterOb);
    }, [sortModel]);

    useEffect(() => {
        if (rowData) {
            specialRowData.current = rowData.data;
        }
    }, [rowData]);

    useEffect(() => {
        setRowCountState((prev) =>
            rowData?.totalCount !== undefined ? rowData?.totalCount : prev
        );
    }, [rowData?.count, setRowCountState]);

    useEffect(() => {
        if (columnStatus === 'success' && columnData) {
            setKey((prev) => prev + 1);
            if (isFilterOn) {
                setColumns(columnData?.filterHeaders?.withComponents);
            } else {
                setColumns(columnData?.filterHeaders?.withoutComponents);
                resetFilters();
            }
        }
    }, [isFilterOn, columnStatus, columnData]);

    const handleAddNewRow = () => {
        refetchRowData();
    };

    /**
     * This function deals with updating cell values. Uses a grid listener so
     * this function is called when cells leave edit mode. That is why we do a check against the values
     * @params GridCellParams
     */
    const handleCellEditStop = useCallback<GridEventListener<'cellEditStop'>>(
        (params) => {
            const data = specialRowData.current;
            const row = data.find((item) => item.id === params.id);
            const { results } = row;
            const key = results.find((k) => k.name === params.field);

            if (key.value === params.value) return;
            updateCellMutation.mutateAsync(
                {
                    configId: params.id as string,
                    body: { keyId: key.keyId, name: key.name, value: params.value }
                },
                {
                    onSuccess: (data) => {
                        toast.success(`Changed dynamic config ${data.name} successfully`, {
                            duration: 1000,
                            position: 'top-center'
                        });
                    },
                    onError: () =>
                        toast.error(`Could not update dynamic config`, {
                            duration: 1000,
                            position: 'top-center'
                        })
                }
            );
        },
        [updateCellMutation]
    );

    if (columnData === null) return <Box>No data to display</Box>;
    // if (rowData === null) return <Box>No data to display</Box>;
    if (rowDataHasError || columnDataHasError) return <Box>table error...</Box>;

    return (
        <>
            <Box>
                <div
                    style={{
                        height: 'calc(100vh - 170px)',
                        width: '98%'
                    }}>
                    <div
                        style={{
                            height: '100%',
                            margin: '20px 0 20px 0'
                        }}>
                        <DataGrid
                            columns={columns || []}
                            columnHeaderHeight={80}
                            rows={rowData?.data || []}
                            rowCount={rowCountState}
                            pagination
                            disableColumnMenu
                            paginationMode="server"
                            key={key}
                            filterMode="server"
                            onPaginationModelChange={setPaginationModel}
                            paginationModel={paginationModel}
                            pageSizeOptions={[20]}
                            sortModel={sortModel}
                            onSortModelChange={setSortModel}
                            onCellEditStop={handleCellEditStop}
                            loading={rowDataIsLoading}
                            slots={{
                                loadingOverlay: StyledLinearProgress
                            }}
                            showColumnVerticalBorder={true}
                            sx={{
                                '& .MuiDataGrid-columnHeader': {
                                    borderColor: isFilterOn ? `${colors.main.accent}` : 'none'
                                },
                                '& .MuiDataGrid-cell--withRightBorder': {
                                    backgroundColor: isFilterOn
                                        ? `${colors.main.accent}`
                                        : 'none'
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: isFilterOn ? `${colors.main.accent}` : 'none'
                                }
                            }}
                        />
                    </div>
                </div>
            </Box>
            {isAddRowModalOpen && (
                <ConfigAddRowModal
                    isOpen={isAddRowModalOpen}
                    setIsOpen={setIsRowModalOpen}
                    columnData={columnData?.componentData}
                    categoryId={categoryId}
                    updateRowData={handleAddNewRow}
                />
            )}
        </>
    );
};

export default ConfigManagerDataTable;
