import React, { useEffect, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Grid, InputAdornment } from '@mui/material';
import colors from 'core/constants/colors';
import { InputLabelPositions } from 'core/constants/common';
import { Icon, IconPaths } from 'components/styled/Icon';
import TooltipMain from 'components/styled/Tooltip';
import { InputMainProps, StyledInput } from 'components/styled/Input/Input';
import { styled } from '@mui/material/styles';

export interface DebouncedInputProps extends Omit<InputMainProps, 'onChange'> {
    debounceTime: number;
    onChange: (inputValue: string) => void;
}

const InputLabel = styled('span')({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: 'max-content'
});

/**
 * Input with delay
 * @param {Function} onKeyDown onKeyDown handler
 * @param {string} label Label value
 * @param {InputLabelPositions} labelPosition Label position
 * @param {string} placeHolder Placeholder
 * @param {boolean} error Is have errors
 * @param {boolean} disabled Is input disabled
 * @param {string} helperText Helper text in bottom
 * @param {boolean} withIcon Use with icon
 * @param {Function} onChange OnChange handler
 * @param {number} debounceTime Value in ms for delay
 * @param {string} value Input value
 * @param {number} width Width
 * @param {JSX.Element} icon Icon value
 * @param {string} id Id
 * @param {boolean} withRemoveIcon Render with remove icon
 * @param {Function} removeHandler Remove handler
 * @constructor
 */
const DebouncedInput = ({
    onKeyDown,
    label,
    labelPosition = InputLabelPositions.top,
    placeHolder,
    error,
    disabled,
    helperText,
    withIcon,
    onChange,
    debounceTime,
    value = '',
    width,
    icon,
    id,
    withRemoveIcon = false,
    removeHandler
}: DebouncedInputProps) => {
    const initialValue = { value };
    const [inputValue, setInputValue] = useState(initialValue);
    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(inputValue.value);
        }, debounceTime);
        return () => clearTimeout(timeout);
    }, [inputValue]);

    let resultIcon = icon;
    if (error && withIcon) {
        resultIcon = (
            <InputAdornment position="end">
                <WarningIcon color={'error'} />
            </InputAdornment>
        );
    }
    if (withRemoveIcon) {
        resultIcon = (
            <TooltipMain title={'Remove'}>
                <Grid
                    onClick={removeHandler}
                    display={'flex'}
                    sx={{
                        cursor: 'pointer'
                    }}>
                    <InputAdornment position="end">
                        <Icon fill={colors.main.primaryDark} path={IconPaths.close}></Icon>
                    </InputAdornment>
                </Grid>
            </TooltipMain>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: labelPosition === InputLabelPositions.left ? 'row' : 'column',
                gap: '6px',
                width: '100%'
            }}>
            {label && <InputLabel>{label}</InputLabel>}
            <StyledInput
                id={id}
                width={width}
                value={inputValue.value}
                onClick={(event) => event.stopPropagation()}
                onKeyDown={onKeyDown}
                onChange={(e) => {
                    const event = e as React.ChangeEvent<HTMLInputElement>;
                    setInputValue({ value: event.target.value });
                }}
                placeholder={placeHolder}
                error={error}
                disabled={disabled}
                helperText={error && helperText}
                InputProps={{
                    endAdornment: resultIcon
                }}
            />
        </Box>
    );
};

export default DebouncedInput;
