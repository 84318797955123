import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISearchPackageGroupProps {
    /**
     * show only tagged documents
     */
    isTaggedOnly: boolean;
    /**
     * show only documents with selected category
     */
    currentCategory: string;
}

export interface ISetSearchPackageGroupPayloadAction {
    /**
     * payload data with filters
     */
    data: ISearchPackageGroupProps;
    /**
     * panel group ID
     */
    keyGroupId: string;
}

export interface ISearchPackageGroupingSlice {
    [key: string]: ISearchPackageGroupProps;
}

const initialState: ISearchPackageGroupingSlice = {};

const searchPackageGroupingSlice = createSlice({
    name: 'searchPackageGrouping',
    initialState,
    reducers: {
        /**
         * Set UI controls data for a particular search package group
         * @param state Slice state
         * @param action Payload with the search package group data to set
         */
        setSearchPackageGroup(
            state: ISearchPackageGroupingSlice,
            action: PayloadAction<ISetSearchPackageGroupPayloadAction>
        ) {
            state[action.payload.keyGroupId] = action.payload.data;
        }
    }
});

export const { setSearchPackageGroup } = searchPackageGroupingSlice.actions;
export default searchPackageGroupingSlice.reducer;
