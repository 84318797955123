import { CreatePulseDocumentFileDto, FullPulseDocumentFile } from 'types/dataModels';
import { handleError } from 'core/helpers/errorHandler';
import { ERROR_TYPES } from 'core/constants/common';
import api from '../api';

/**
 * This function makes a GET request to the server and returns the response as an ArrayBuffer.
 * @function
 * @category API
 * @subcategory examBlobDocumentFile
 * @param {string} orderId - string - The order id of the exam order
 * @param {string} documentId - string - The document id,
 * @param {string} fileId - string - The file id of the document
 * @returns {{buffer: ArrayBuffer, type: string}}
 */
export const getExamDocumentFile = async (
    orderId: string,
    documentId: string,
    fileId: string
): Promise<{ buffer: ArrayBuffer; type: string }> => {
    try {
        const response = await api.getPdfFile<ArrayBuffer>(
            `/api/exam/${orderId}/document/${documentId}/file/${fileId}/download`
        );
        return { buffer: response.data, type: response.headers['content-type'] };
    } catch (e) {
        handleError(e);
    }
};

/**
 * It overrides an existing file in an existing document in an existing exam order
 * @function
 * @category API
 * @subcategory examBlobDocumentFile
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The ID of the document you want to override the file for.
 * @param {string} fileId - The id of the file you want to override.
 * @param {File} file - File - the file to be uploaded
 * @returns {string}
 */
export const overrideExamDocumentFile = async (
    orderId: string,
    documentId: string,
    fileId: string,
    file: File
): Promise<string> => {
    try {
        const formData = new FormData();
        formData.set('files', file);
        const response = await api.put<FormData, string>(
            `/api/exam/${orderId}/document/${documentId}/file/${fileId}?overwriteImageFiles=true`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    } catch (e) {
        handleError(e, ERROR_TYPES.fileUpload);
    }
};

/**
 * It deletes a file from a document in an exam order
 * @function
 * @category API
 * @subcategory examBlobDocumentFile
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The ID of the document you want to delete a file from.
 * @param {string} fileId - The id of the file you want to delete
 * @returns {string}
 */
export const deleteExamDocumentFile = async (
    orderId: string,
    documentId: string,
    fileId: string
): Promise<string> => {
    try {
        const response = await api.delete(
            `/api/exam/${orderId}/document/${documentId}/file/${fileId}/delete`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It uploads a file to the server and returns the file's metadata
 * @function
 * @category API
 * @subcategory examBlobDocumentFile
 * @param {string} orderId - string - the order id of the exam
 * @param {string} documentId - The ID of the document you want to upload a file to.
 * @param {File} file - File - the file to be uploaded
 * @param {booelan} overwriteImageFiles
 * @param {booelan} mergeImageFiles
 * @returns {FullPulseDocumentFile}
 */
export const postExamDocumentFile = async (
    orderId: string,
    documentId: string,
    file: File,
    overwriteImageFiles = false,
    mergeImageFiles = false
): Promise<FullPulseDocumentFile[]> => {
    const formData = new FormData();
    formData.set('files', file);
    try {
        const overwriteQueryParam = overwriteImageFiles
            ? 'overwriteImageFiles=true'
            : 'overwriteImageFiles=false';

        const mergeQueryParam = mergeImageFiles
            ? 'mergeImageFiles=true'
            : 'mergeImageFiles=false';

        const endpoint = `/api/exam/${orderId}/document/${documentId}/file/upload?${overwriteQueryParam}&${mergeQueryParam}`;
        const response = await api.post<FormData, FullPulseDocumentFile[]>(
            endpoint,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    } catch (e) {
        handleError(e, ERROR_TYPES.fileUpload);
    }
};

/**
 * It takes an orderId and a files array, and returns array of CreatePulseDocumentFileDto objects
 * @function
 * @category API
 * @subcategory examBlobDocumentFile
 * @param {string} orderId - string - the order id of the exam
 * @param {File[]} files - File[] - this is the array of files that you want to upload.
 * @returns {CreatePulseDocumentFileDto[]}
 */
export const uploadImage = async (
    orderId: string,
    files: File[]
): Promise<CreatePulseDocumentFileDto[]> => {
    try {
        const formData = new FormData();
        formData.set('Files', files[0] || JSON.stringify([]));
        const response = await api.post<FormData, CreatePulseDocumentFileDto[]>(
            `/api/exam/${orderId}/image`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    } catch (e) {
        handleError(e, ERROR_TYPES.fileUpload);
    }
};
