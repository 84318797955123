import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { ProjectStatus } from 'types/dataModels';

interface ProjectStatusState {
    projectStatus: ProjectStatus[];
}

const initialState: ProjectStatusState = {
    projectStatus: []
};

const ProjectStatusSlice = createSlice({
    name: 'projectStatus',
    initialState,
    reducers: {
        setProjectStatus(state, action: PayloadAction<ProjectStatus[]>) {
            state.projectStatus = action.payload;
        }
    }
});

export const { setProjectStatus } = ProjectStatusSlice.actions;

/**
 * Fetch project statuses data from BE
 * @returns {AppThunk}
 */
export const fetchProjectStatuses = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.projectStatuses.getProjectStatuses();
        dispatch(setProjectStatus(response));
    } catch (err) {
        dispatch(setProjectStatus([]));
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get project status: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default ProjectStatusSlice.reducer;
