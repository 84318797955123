import { makeStyles } from "tss-react/mui";

const styles = makeStyles()(theme => ({
    layout: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        width: '100%'
    },
    main: {
        padding: '12px',
        maxHeight: 'calc(100vh - 65px)',
        gap: '12px',
        display: 'grid',
        gridTemplateRows: 'auto 1fr'
    },
    panels: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflow: 'auto',
        width: '100%',
        height: '100%'
    },
    fullScreenSidebarCodebook: {
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'wrap'
    },
    fullScreenSidebarWithoutCodebook: {
        display: 'none'
    },
    workbenchWithSmallSidebar: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto'
    },
    workbenchWithRegularSidebar: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '70% 30%'
    },
    workbenchWithFullSidebar: {
        display: 'none'
    },
    fullScreenSidebar: {
        display: 'block',
        height: '100%'
    },
    workbenchWithCodebook: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '70% 30%'
    },
    workbenchWithPopoutWindow: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        width: '100%',
        gridTemplateColumns: '1fr'
    },
    codebookContainer: {
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    sidebarHidden: {
        display: 'none'
    },
    sidebarContainer: {
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        boxSizing: 'border-box',
        display: 'flex',
        flexFlow: 'wrap',
        width: '100%'
    }
}));

export default styles;
