import { FileUploadError } from 'core/helpers/errors';
import { DEFAULT_FILE_UPLOAD_ERROR, ERROR_TYPES } from 'core/constants/common';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleError = (e: any, type: string = null) => {
    if (type === ERROR_TYPES.fileUpload) {
        if (e.response) {
            const { status, data } = e.response;
            if (status === 400) throw new FileUploadError(data?.Message);
            else throw new FileUploadError(DEFAULT_FILE_UPLOAD_ERROR);
        }
        throw new FileUploadError(e.message);
    }

    if (e.response?.data?.Message) {
        throw new Error(e.response?.data?.Message);
    }

    throw new Error(e.message);
}