import { v4 as uuidv4 } from 'uuid';
import {
    CreatePulseDocumentFileDto,
    DocumentAddReferenceDto,
    NewRecordedDocumentWithFilesDto,
    NewCommonDocumentWithFilesDto,
    PulseDocumentParty
} from 'types/dataModels';
import { UploadKeyDocumentFormData } from 'types/uploadKeyDocumentForm';
import { UploadSearchPackageFormData } from 'types/uploadSearchPackageForm';

/**
 * Create data for upload recorded document from user input form
 * @function
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {UploadKeyDocumentFormData} formData
 * @param {string} propertyId
 * @param {string} searchType - Document search type
 * @param {string} argumentsCompositeValue - Document argument composite value
 * @returns {NewRecordedDocumentWithFilesDto}
 */
export const createDataForRecordedDocumentUpload = (
    uploadedFiles: CreatePulseDocumentFileDto[],
    formData: UploadKeyDocumentFormData,
    propertyId: string,
    searchType = '',
    argumentsCompositeValue = ''
): NewRecordedDocumentWithFilesDto => {
    /**
     * Get number[] of id's from CodeTemplate[]
     */
    const codeTemplateIds: number[] = formData.codes.reduce(
        (acc, current) => [...acc, current.id],
        []
    );

    /**
     * Convert ExamOrderReferencedDocument[] to DocumentAddReferenceDto[]
     */
    const existingReferencedDocuments: DocumentAddReferenceDto[] =
        formData.foundReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: ref.id,
                instrumentNumber: '',
                instrumentYear: null,
                liber: '',
                page: ''
            };
        });

    /**
     * Create DocumentAddReferenceDto[] from input received from user for not existing references for current order
     */
    const notExistingReferencedDocuments: DocumentAddReferenceDto[] =
        formData.notExistingReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: null,
                instrumentNumber: ref.instrumentNumber,
                instrumentYear: ref.instrumentYear,
                liber: ref.book,
                page: ref.page
            };
        });

    /**
     * Concat existing and not existing references
     */
    const references: DocumentAddReferenceDto[] = [
        ...existingReferencedDocuments,
        ...notExistingReferencedDocuments
    ];

    /**
     * Convert PulseDocumentPartyField[] to PulseDocumentParty[]
     */
    const parties: PulseDocumentParty[] = formData.parties.reduce(
        (acc, currentValue) => [...acc, currentValue],
        [] as PulseDocumentParty[]
    );
    return {
        documentType: {
            documentCategoryId: formData.category.id,
            documentSubCategoryId: formData.subCategory.id,
            sourceDocumentTypeName: ''
        },
        propertyId,
        documentSource: {
            isSearch: false,
            examinerSearchType: searchType ?? '',
            searchArgumentsCompositeValue: argumentsCompositeValue ?? ''
        },
        instrumentNumber: formData?.instrumentNumber ?? '',
        instrumentYear: formData.instrumentYear,
        bookType: '',
        liber: formData?.book ?? '',
        page: formData?.page ?? '',
        documentNumber: formData?.instrumentNumber ?? '',
        recordedDate: formData?.recordedDate === '' ? null : formData?.recordedDate,
        datedDate: formData?.instrumentDate === '' ? null : formData?.instrumentDate,
        effectiveDate: null,
        parties: parties,
        documentLegalDescription: '',
        notes: formData?.notes !== '' ? [{ id: uuidv4(), note: formData?.notes }] : [],
        codeTemplateIds: codeTemplateIds,
        files: uploadedFiles,
        references,
        amount: formData.amount,
        transferTaxAmount: formData.transferTaxAmount
    };
};

export const createDataForCommonDocumentUpload = (
    uploadedFiles: CreatePulseDocumentFileDto[],
    formData: UploadSearchPackageFormData,
    propertyId: string
): NewCommonDocumentWithFilesDto => {
    return {
        documentType:
            formData.category.id && formData.subCategory.id
                ? {
                      documentCategoryId: formData.category.id,
                      documentSubCategoryId: formData.subCategory.id,
                      sourceDocumentTypeName: ''
                  }
                : {
                      sourceDocumentTypeName: ''
                  },
        propertyId,
        instrumentNumber: formData?.instrumentNumber ?? '',
        recordedDate: formData?.recordedDate === '' ? null : formData?.recordedDate,
        codeTemplateIds: [],
        files: uploadedFiles,
        searchCriteria: formData.searchCriteria
    };
};
