import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { County } from 'types/dataModels';

interface CountyState {
    county: County[];
}

const initialState = {
    county: []
} as CountyState;

const countySlice = createSlice({
    name: 'county',
    initialState,
    reducers: {
        /**
         * Set a list of counties to store
         * @param state Slice state
         * @param action Payload with Counties to set
         */
        setCounty(state, action: PayloadAction<County[]>) {
            state.county = action.payload;
        },
        /**
         * Set a list of counties for a particular state to store
         * @param state Slice state
         * @param action Payload with Counties to set
         */
        setStateCounty(state, action: PayloadAction<County[]>) {
            state.county = action.payload;
        }
    }
});

export const { setCounty, setStateCounty } = countySlice.actions;

/**
 * Fetch list of counties and save response data to store
 * @returns {AppThunk}
 */
export const fetchCounty = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.county.getCounty();
        dispatch(setCounty(response));
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get counties array: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

/**
 * Fetch list of counties for a particular state and save response data to store
 * @param {number} stateId ID of the state
 * @returns {AppThunk}
 */
export const fetchStateCounty =
    (stateId: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.stateCounty.getStateCounty(stateId);
            dispatch(setStateCounty(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get state/counties array: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default countySlice.reducer;
