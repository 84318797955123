import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IPopoutWindowSlice {
    /**
     * browser window object of popout
     */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    browserWindowObject: Window | any;
}

const initialState: IPopoutWindowSlice = {
    browserWindowObject: null
};

const PopoutWindowSlice = createSlice({
    name: 'popoutWindow',
    initialState,
    reducers: {
        /**
         *
         * @param state slice state
         * @param action payload with popout browser window object to set
         */
        setPopoutBrowserWindowObject(
            state: IPopoutWindowSlice,
            action: PayloadAction<IPopoutWindowSlice['browserWindowObject']>
        ) {
            state.browserWindowObject = action.payload;
        }
    }
});

export const { setPopoutBrowserWindowObject } = PopoutWindowSlice.actions;

export default PopoutWindowSlice.reducer;
