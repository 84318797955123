import { Grid, Box, Modal } from '@mui/material';
import { ColumnType } from 'types/configManager'; //UpdateFilterObj, ForeignKeyWithMenuItems
import { Icon, IconPaths } from 'components/styled/Icon';
import IconButton from 'components/styled/IconButton';
import InputMain from 'components/styled/Input';
import {
    GridRenderEditCellParams,
    GridCellEditStopReasons,
    GridRenderCellParams
} from '@mui/x-data-grid';
import MainButton from 'components/styled/MainButton';
import { ButtonVariants } from 'core/constants/common';
import TextArea from 'components/styled/TextArea';
import { ColCallbackOptions } from 'core/helpers/generateDataGrid';
import colors from 'core/constants/colors';

const BUTTON_TEXT_LENGTH = 22;

export const createCellComponent = (
    colType: ColumnType,
    params?: GridRenderEditCellParams,
    type?: number
    // foreignKeyMenuItemsObj?: ForeignKeyWithMenuItems,
    // colWidth?: number
) => {
    // These fields are not editable, so we just need to format/show it properly
    if (colType === ColumnType.ForeignKey) {
        return <>{params.value}</>;
    }

    return determineCellComponent(type, params);
};

const determineCellComponent = (type: number, params: GridRenderEditCellParams) => {
    switch (type) {
        case 0:
            return createTextRowComponent(params);
        case 1:
            return createPopoutTextEditorComponent(params); // Special case for long strings , updateCellCallback
        case 3: // Number
            return createNumberRowComponent(params);
        default:
            return <>{params.value}</>;
    }
};

const createTextRowComponent = (params: GridRenderEditCellParams) => {
    const { id, field } = params;
    const handleOnChange = (value: string) => {
        params.api.setEditCellValue({ id, field, value: value });
        const tempParams = params.api.getCellParams(id, field);
        params.api.publishEvent('cellEditStop', {
            ...tempParams,
            value: value,
            reason: GridCellEditStopReasons.enterKeyDown
        });
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InputMain
                width={params.colDef.computedWidth}
                value={params.value}
                onChange={handleOnChange}
            />
        </Box>
    );
};

const createPopoutTextEditorComponent = (params: GridRenderEditCellParams) => {
    return (
        <Modal
            open={params.hasFocus}
            disableEnforceFocus={false}
            sx={{ left: '60px', top: '205px' }}>
            <Grid sx={{ margin: 'auto 20px', background: colors.main.primaryLight }}>
                <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Grid
                        sx={{
                            width: '100%',
                            padding: '8px 16px',
                            backgroundColor: colors.neutral.neutralLight
                        }}>
                        <span
                            color={
                                colors.main.secondaryDark
                            }>{`Editing ${params.field} for ${params.row.Name}`}</span>
                    </Grid>
                    <Grid
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        sx={{
                            backgroundColor: colors.neutral.neutralLight,
                            paddingRight: '10px'
                        }}>
                        <IconButton
                            withoutBackground
                            icon={
                                <Icon
                                    path={IconPaths.unzoom}
                                    fill={colors.neutral.neutralMid}
                                />
                            }
                            onClick={() =>
                                params.api.stopCellEditMode({
                                    id: params.id,
                                    field: params.field,
                                    ignoreModifications: true
                                })
                            }
                        />
                    </Grid>
                </Grid>
                <Grid sx={{ padding: '20px' }}>
                    <TextArea
                        value={params.value}
                        rows={10}
                        fullWidth={true}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                event.stopPropagation();
                                const target = event.target as unknown as HTMLInputElement;
                                const selStart = target.selectionStart;
                                const val = target.value;
                                const before = val.substring(0, selStart);
                                const after = val.substring(selStart);
                                params.api.setEditCellValue({
                                    id: params.id,
                                    field: params.field,
                                    value: before + '\n' + after
                                });
                                // A little bit gross but this ensures that the selection cursor is positioned correctly
                                // when adding a new line.
                                setTimeout(() => {
                                    target.setSelectionRange(selStart + 1, selStart + 1);
                                });
                            }
                        }}
                        onChange={(ev) => {
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: ev.target.value
                            });
                        }}
                    />
                </Grid>
                <Grid display={'flex'} flexDirection={'row'} sx={{ padding: '0 0 12px 20px' }}>
                    <Grid display={'flex'} flexDirection={'row'} gap={'10px'}>
                        <MainButton
                            text="Save"
                            action={() => {
                                // params.api.stopCellEditMode({
                                //     id: params.id,
                                //     field: params.field,
                                //     ignoreModifications: false
                                // });
                                const tempParams = params.api.getCellParams(
                                    params.id,
                                    params.field
                                );

                                params.api.publishEvent('cellEditStop', {
                                    ...tempParams,
                                    value: params.value,
                                    reason: GridCellEditStopReasons.enterKeyDown
                                });
                            }}
                        />
                        <MainButton
                            text="Cancel"
                            action={() => {
                                params.api.stopCellEditMode({
                                    id: params.id,
                                    field: params.field,
                                    ignoreModifications: true
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

const createNumberRowComponent = (params: GridRenderEditCellParams) => {
    const { id, value, field } = params;
    const width = params.colDef.computedWidth;

    const handleOnChange = (value: string) => {
        params.api.setEditCellValue({ id, field, value: value });
        const tempParams = params.api.getCellParams(id, field);
        params.api.publishEvent('cellEditStop', {
            ...tempParams,
            value: value,
            reason: GridCellEditStopReasons.enterKeyDown
        });
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InputMain
                isOnlyInteger={true}
                width={width}
                value={value || ''}
                onChange={handleOnChange}
            />
        </Box>
    );
};

/**
 * Creates the button we render on each row to delete the row
 * @param funcs
 * @param id
 * @returns {JSX.Element}
 */
export const DeleteComponent = (funcs: ColCallbackOptions, id: string) => {
    return (
        <MainButton
            text="Delete"
            variant={ButtonVariants.secondary}
            size="small"
            action={() => funcs.deleteRowCallback(id)}
        />
    );
};

export const createDisplayComponent = (params: GridRenderCellParams, type: number) => {
    if (type === 1) {
        // console.log(params);
        // console.log(params.value);
        let buttonText = '';
        if (params?.value) {
            if (params.value.length >= BUTTON_TEXT_LENGTH) {
                buttonText = params.value.substring(0, BUTTON_TEXT_LENGTH) + '...';
            } else {
                buttonText = params.value;
            }
        } else {
            buttonText = 'Add ' + params?.field;
        }
        return (
            <MainButton
                variant={ButtonVariants.secondary}
                text={buttonText}
                action={() => {
                    params.api.startCellEditMode({ id: params.id, field: params.field });
                }}
            />
        );
    } else {
        return <>{params.value}</>;
    }
};
