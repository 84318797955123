import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { ExaminerDocumentType } from 'types/dataModels';

interface DocumentTypesState {
    documentTypes: ExaminerDocumentType[];
}

const initialState: DocumentTypesState = {
    documentTypes: []
};

const DocumentTypesSlice = createSlice({
    name: 'documentTypes',
    initialState,
    reducers: {
        /**
         * Set a list of document types to store
         * @param state Slice state
         * @param action Payload with the list of document types to set
         */
        setDocumentTypes(state, action: PayloadAction<ExaminerDocumentType[]>) {
            state.documentTypes = action.payload;
        }
    }
});

export const { setDocumentTypes } = DocumentTypesSlice.actions;

/**
 * Fetch list of document types and save response data to store
 * @returns {AppThunk}
 */
export const fetchDocumentTypes = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.documentTypes.getDocumentType();
        dispatch(setDocumentTypes(response));
    } catch (err) {
        dispatch(setDocumentTypes([]));
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get document types: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default DocumentTypesSlice.reducer;
